$font-url: '/fonts';
@import 'component-core.scss';

.switch-control {
    height: 32px;
    border: 1px solid grayscaleColor(4);
    background-color: grayscaleColor(1);
    cursor: pointer;
    display: flex;
    /* relative positioning required to apply the z-index on the hover/focus outline */
    position: relative;

    &:focus,
    &:hover {
        outline: 1px solid $color-input-border-focus;
        z-index: $zindex-active;
    }

    &.switch-control--disabled {
        cursor: default;
        background-color: grayscaleColor(2);
        color: grayscaleColor(5);
        opacity: 0.75;

        &:focus,
        &:hover {
            outline: none;
        }
    }

    .switch-control__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        overflow-y: hidden;
        flex-grow: 1;

        .switch-control__list-item {
            line-height: 32px;
            user-select: none;
            margin-left: 8px;
            overflow: hidden;

            &.switch-control__list-item--selected {
                max-height: 32px;
            }

            &.switch-control__list-item--not-selected {
                max-height: 0;
            }
        }
    }

    .indicator-component {
        margin: auto 0 auto 8px;
    }

    .switch-control__navigation {
        display: flex;
        flex-direction: column;
        padding: 0 8px;

        .glyph {
            font-size: 10px;
            margin: 0;

            &.glyph--up-arrow {
                margin-top: auto;
            }

            &.glyph--down-arrow {
                margin-bottom: auto;
            }
        }
    }
}
