$font-url: '/fonts';
@import 'component-core.scss';

.radio-group {
    .horizontal-list__item {
        margin-right: $size-s;
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__adgroup-create-channel.scss';
    @import 'themes/theme__audience-data-group-type.scss';
    @import 'themes/theme__bid-list-adjustment-type.scss';
    @import 'themes/theme__grapeshot-page-quality.scss';
    @import 'themes/theme__page-quality.scss';
    @import 'themes/theme__radio-group-list.scss';
    @import 'themes/theme__small-glyph-only.scss';
    @import 'themes/theme__small-floating-glyph-only.scss';
    @import 'themes/theme__filter.scss';
    @import 'themes/theme__wider-radio-group.scss';
    @import 'themes/theme__wider-radio-group-3-items.scss';
    @import 'themes/theme__brand-lift-campaign-settings';
    @import 'themes/theme__miaozhen-settings';
}

.radio-group--vertical-reverse {
    display: flex;
    flex-direction: column-reverse;
}

.radio-group--block,
.radio-group--block-reverse {
    display: inline-block;

    // Radiobuttons stacked horizontally, with no visible control.
    .horizontal-list {
        .radio-control__label {
            padding: 0 $size-xs;
            height: 100%;
            width: 100%;
            display: block;
            background: grayscaleColor(1);
            text-align: center;
            line-height: $size-m;
            transition: all 50ms ease-in;
            border: 1px solid grayscaleColor(4);
            color: grayscaleColor(5);

            &:before,
            &:after {
                display: none;
            }
        }

        // Enabled radios should be colored.
        .radio-control--enabled {
            .radio-control__label--selected {
                background: primaryTint(1, 5);
                border-color: primaryColor(1);
                color: primaryColor(1);
                z-index: $zindex-active;
            }

            /* stylelint-disable-next-line selector-combinator-whitelist */
            .radio-control__input:focus + .radio-control__label--not-selected,
            .radio-control__label--not-selected:hover {
                border-color: primaryColor(1);
                color: grayscaleColor(6);
                z-index: $zindex-active;
            }
        }

        // Disabled radios are grayscale.
        .radio-control__label--disabled,
        .radio-control__label--disabled:hover {
            background: grayscaleColor(2);
            border-color: grayscaleColor(4);
            color: grayscaleColor(4);
        }

        .radio-control__label--disabled.radio-control__label--selected {
            background: grayscaleColor(3);
        }

        .horizontal-list__item {
            margin-right: -1px;
            flex: 1 1 0;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .radio-control {
        margin-bottom: 0;
        height: $size-m;
    }
}

.radio-group--block-reverse {
    .horizontal-list {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}

.radio-group--with-glyph {
    .radio-control {
        height: 80px;
        min-width: 104px;

        .radio-control__label {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .radio-group__glyph {
            @include glyphSize(18px);
        }

        .radio-group__glyph-label {
            line-height: 26px;
        }
    }
}
