$font-url: '/fonts';
@import 'component-core.scss';

.datatable.datatable--audience {
    .datatable__body {
        div:not(:last-child) .datatable__row {
            .datatable__column--first {
                overflow: visible;
            }
        }
    }
}
