$font-url: '/fonts';
@import 'component-core.scss';

.select-list.select-list--multiple {
    &.drop-down-menu__main {
        .select-list-item.select-list-item--multiple {
            &:last-of-type {
                margin-bottom: 0;
            }

            &.select-list-item--disabled {
                &.drop-down-menu-item--active,
                &:hover {
                    .checkbox-control label {
                        font-weight: normal;
                    }
                }

                .checkbox-control {
                    label {
                        @include textStyle($color: grayscaleColor(5));
                    }
                }
            }

            &.select-list-item--hidden {
                display: none;
            }

            .checkbox-control {
                padding-left: 23px;
                margin-bottom: 0;

                .checkbox-control__wrapper {
                    display: flex;
                }

                label {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: inline-block;
                    width: 100%;
                    // Center text vertically within the label
                    line-height: $select-list-item-height;
                }
            }
        }

        .drop-down-menu__label {
            .drop-down-menu__label-text {
                .header {
                    display: inline-block;
                }

                .skittle {
                    margin-left: 5px;
                    text-align: center;
                    display: inline-block;
                    width: 17px;
                    height: 17px;
                    border-radius: 8.5px;
                    font-size: 10px;
                    line-height: 19.5px;
                    color: grayscaleColor(1);
                    background: primaryColor(1);

                    &.count2 {
                        background: primaryColor(2);
                    }

                    &.count3 {
                        background: primaryColor(3);
                    }

                    &.count4 {
                        background: primaryColor(5);
                    }

                    &.count5 {
                        background: primaryColor(8);
                    }

                    &.count6 {
                        background: primaryColor(4);
                    }

                    &.count7 {
                        background: primaryColor(6);
                    }
                }
            }
        }
    }

    &--color-indicator {
        @each $i in $primary-palette-numbers {
            &.select-list--color-indicator-#{$i} a:before {
                background-color: primaryColor($i);
            }
        }

        .drop-down-menu__label-text {
            width: calc(100% - 35px);
            margin-left: 5px;
        }

        a:before {
            content: '';
            display: block;
            width: 5px;
            height: 32px;
            position: absolute;
            left: -1px;
            top: -1px;
        }
    }
}
