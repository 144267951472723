@include theme('error') {
    background-color: $color-tooltip-background-error;

    &.tooltip--top {
        .tooltip__arrow {
            border-top-color: $color-tooltip-background-error;
        }
    }

    &.tooltip--bottom {
        .tooltip__arrow {
            border-bottom-color: $color-tooltip-background-error;
        }
    }

    &.tooltip--right {
        .tooltip__arrow {
            border-right-color: $color-tooltip-background-error;
        }
    }

    &.tooltip--left {
        .tooltip__arrow {
            border-left-color: $color-tooltip-background-error;
        }
    }
}
