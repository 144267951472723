$font-url: '/fonts';
@import 'component-core.scss';

.overflow-filters__dialog {
    border-radius: 16px;
}

.overflow-filters__dialog__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 0 0 20px;
    min-height: 320px;
    max-height: calc(100vh - 200px);
    justify-content: space-between;
}

.overflow-filters__dialog__content__header {
    display: flex;
    margin-bottom: 20px;
    padding-right: 20px;
}

.overflow-filters__dialog__content__header__title {
    @include textStyle($weight: 'Heavy', $size: 18px);
    flex: 1;
    text-align: center;
}

.overflow-filters__dialog__content__buttons {
    display: flex;
    flex-direction: row-reverse;
    padding: 16px 20px 16px 0;
    border-top: 1px solid grayscaleColor(4);
    justify-content: space-between;
}

.overflow-filters__dialog__content__filters {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    margin-bottom: 16px;
}

.overflow-filters__dialog__content__filters__filter {
    flex-basis: 50%;
    margin-bottom: 20px;
    padding: 2px;
}

.overflow-filters__label {
    margin: 8px;
}
