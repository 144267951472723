// Sizes a glyph.  Useful when the glyph needs to be the same color as the surrounding text.
// $size is the size (e.g., 14px) of the longest side of the glyph.
@mixin glyphSize($size) {
    font-size: $size;
}

// Styles a glyph.
// $size is the size (e.g., 14px) of the longest side of the glyph.
// $color is the color of the glyph.
@mixin glyphStyle($size, $color) {
    @include glyphSize($size);
    color: $color;
}

// Styles a glyph when it is used with a circular, colored badge).
@mixin glyphStyleWithBadge(
    $glyphSize,         // Size (e.g., 14px), of the longest side of the glyph.
    $badgeDiameter,     // Size (e.g., 20px) of the diameter of the circular badge in which the glyph sits.
    $glyphColor,        // Color of the glyph (usually the coordinating text color of the $badgeColor).
    $badgeColor) {      // Color of the badge.

    @include glyphStyle($glyphSize, $glyphColor);
    border-radius: 50%;
    width: $badgeDiameter;
    height: $badgeDiameter;
    background-color: $badgeColor;
    // We need to ensure `flex` is applied for the background to render properly.
    // This should be set automatically from the parent's `.glyph`, but children may
    // accidentally have overwritten it.
    display: flex;
}
