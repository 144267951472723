$font-url: '/fonts';
@import 'component-core.scss';

/* This file contains resets and normalizations. As such, it needs to target elements directly. */
/* stylelint-disable selector-max-type */
@import './variables/color-palette.scss';
@import '~@material/ripple/dist/mdc.ripple.css';

html {
    box-sizing: border-box;
}

html,
body {
    @include bodyText();
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: grayscaleColor(2);

    a {
        color: var(--color-link);
        text-decoration: none;
    }

    h1,
    h2,
    h3 {
        font-family: 'AvenirLT-Heavy';
        font-weight: normal;
    }
}

body {
    // The below generates our primary color palette, from a defined set of hex values

    /* 1 - TTD Blue */
    @include generatePrimaryColorFromHexValues(
        #0099fa,
        1,
        (
            1: #2eaeff,
            2: #65c6ff,
            3: #97daff,
            4: #c0eaff,
            5: #e6f7ff,
        ),
        (
            1: #007cd5,
            2: #0060aa,
            3: #004580,
        )
    );
    /* 2 - Marine */
    @include generatePrimaryColorFromHexValues(
        #004fc2,
        2,
        (
            1: #316edc,
            2: #618eef,
            3: #a2bbfb,
            4: #cad8ff,
            5: #f2f5ff,
        ),
        (
            1: #003caf,
            2: #002a92,
            3: #001a66,
        )
    );
    /* 3 - Deep Reef */
    @include generatePrimaryColorFromHexValues(
        #00aab4,
        3,
        (
            1: #32c4c8,
            2: #65dada,
            3: #97e9e6,
            4: #caf5f2,
            5: #e9fdfb,
        ),
        (
            1: #0096a0,
            2: #007a83,
            3: #00555c,
        )
    );
    /* 4 - Amethyst */
    @include generatePrimaryColorFromHexValues(
        #7c2cfe,
        4,
        (
            1: #934bff,
            2: #ac71ff,
            3: #c79dff,
            4: #e4d1ff,
            5: #f7f1ff,
        ),
        (
            1: #6713d0,
            2: #5301a2,
            3: #3e0073,
        )
    );
    /* 5 - Sunshine */
    @include generatePrimaryColorFromHexValues(
        #ffbb0b,
        5,
        (
            1: #ffcf39,
            2: #ffe067,
            3: #ffed95,
            4: #fff7c4,
            5: #fffbe3,
        ),
        (
            1: #e49e00,
            2: #c97900,
            3: #ad5200,
        )
    );
    /* 6 - Sea Foam */
    @include generatePrimaryColorFromHexValues(
        #6ecd99,
        6,
        (
            1: #85dcac,
            2: #9fe9c0,
            3: #bcf3d4,
            4: #dbfae9,
            5: #eefcf4,
        ),
        (
            1: #51a788,
            2: #377d72,
            3: #204f51,
        )
    );
    /* 7 - Fuchsia */
    @include generatePrimaryColorFromHexValues(
        #e1128f,
        7,
        (
            1: #f24bae,
            2: #fe78c4,
            3: #ffacdb,
            4: #ffe3f3,
            5: #fff3fa,
        ),
        (
            1: #c9007a,
            2: #a20062,
            3: #730049,
        )
    );
    /* 8 - Sunset */
    @include generatePrimaryColorFromHexValues(
        #ff7e12,
        8,
        (
            1: #ff9d37,
            2: #ffb961,
            3: #ffd391,
            4: #ffe9c2,
            5: #fef4e3,
        ),
        (
            1: #e76200,
            2: #cf4500,
            3: #b82800,
        )
    );

    --neutral1: #fcf9f6; /* 1 - ? */
    --neutral2: #f9f2ed; /* 2 - ? */
    --neutral3: #f3e6db; /* 3 - ? */
    --neutral4: #ecd9c8; /* 4 - ? */
    --neutral5: #e6cdb6; /* 5 - ? */
    --neutral6: #e0c0a4; /* 6 - ? */
    --neutral7: #b39a83; /* 7 - ? */
    --neutral8: #867362; /* 8 - ? */
    --neutral9: #5a4d42; /* 9 - ? */

    --greyscale1: #ffffff; /* 1 - ? */
    --greyscale2: #f2f2f3; /* 1 - ? */
    --greyscale3: #e6e7e7; /* 1 - ? */
    --greyscale4: #cacccb; /* 1 - ? */
    --greyscale5: #9a9a9a; /* 1 - ? */
    --greyscale6: #666767; /* 1 - ? */
    --greyscale7: #323333; /* 1 - ? */

    // Below are variables that can be whiteLabeled,
    // Redefine them in a whitelabel file to override.
    // Use of these variables should be by using the sass version in semantic-palette.scss

    // Site Toolbar Colors

    --color-site-toolbar-background: #{primaryColor(1)};
    --color-site-toolbar-text: #{textColorForPrimaryShade(1, 3)};

    // Breadcrumbs Colors

    --color-link: #{primaryShade(1, 1)};
    --color-breadcrumb-item: var(--color-link);
    --color-breadcrumb-item-active: #{primaryShade(1, 3)};

    // Dialog Colors

    --color-dialog-header-background: #{primaryColor(1)};
    --color-dialog-header-text: #{textColorForPrimaryColor(1)};

    --color-dialog-content-background: #{grayscaleColor(1)};

    // Control Colors

    --color-control-basic: #{primaryColor(1)};

    // Validation Colors

    --color-validation-error-text: #{stoplightColor('red')};
    --color-validation-error-border: #{stoplightColor('red')};
    --color-validation-error-border-rgb: #{red(stoplightColor('red'))},
        #{green(stoplightColor('red'))}, #{blue(stoplightColor('red'))};

    // Help Colors

    --color-help-button: #{grayscaleColor(1)};
    --color-help-button-background: #{primaryColor(1)};
    --color-help-button-hover: #{primaryTint(1, 5)};
    --color-help-button-hover-background: #{primaryShade(1, 1)};

    --color-help-button-open: #{primaryTint(1, 4)};
    --color-help-button-open-background: #{primaryColor(2)};
    --color-help-border: #{grayscaleColor(3)};
    --color-help-background: #{grayscaleColor(2)};

    // Targeting Type Colors

    --color-adgroup-targeting-rail: #{grayscaleColor(5)};
    --color-adgroup-targeting-rail-text: #{textColorForGrayscaleColor(5)};
    --color-adgroup-targeting-manual: #{primaryColor(2)};
    --color-adgroup-targeting-manual-text: #{textColorForPrimaryColor(2)};
    --color-adgroup-targeting-auto: #{stoplightColor('green')};
    --color-adgroup-targeting-auto-text: #{grayscaleColor(1)};

    // Card Colors

    --color-card-border: #{grayscaleColor(3)};
    --color-card-background: #{grayscaleColor(1)};

    // Tool Tips

    --color-tooltip-text: #{grayscaleColor(1)};
    --color-tooltip-background-default: #{grayscaleColor(7)};
    --color-tooltip-background-error: #{stoplightColor('red')};

    // Drop Down Menus

    --color-drop-down-menu-background: #{grayscaleColor(1)};
    --color-drop-down-menu: #{grayscaleColor(7)};

    // Activity Log Charts

    --color-activity-log-chart-series-one: #{primaryColor(6)};
    --color-activity-log-chart-series-two: #{primaryTint(1, 1)};

    // Other Colors

    --color-horizontal-rule: #{grayscaleColor(3)};

    --color-recommendations-indicator: #{stoplightColor('yellow')};
    --color-recommendations-indicator-text: #{grayscaleColor(1)};

    --color-labelled-value-label: #{grayscaleColor(5)};

    // #f8f8f8 (hint of gray) at 40% opacity on a white background
    --color-lightest-gray-background: #fcfcfc;
    --color-lightest-gray-background-border: #f5f5f5;

    // Input
    --color-input-border: #{grayscaleColor(4)};
    --color-input-text: #{grayscaleColor(6)};
    --color-input-label: #{grayscaleColor(5)};
    --color-input-border-focus: #{primaryTint(1, 2)};
    --color-input-inline-border-focus: #{primaryColor(1)};
    --color-input-place-holder: #{grayscaleColor(5)};
    --color-input-place-holder-focus: #{grayscaleColor(4)};
    --color-input-disabled-text: #{grayscaleColor(5)};
    --color-input-disabled-background: #{grayscaleColor(2)};

    // Ad Group Type Color Variations

    // Template
    --color-adgroup-type-template: #{grayscaleColor(7)};

    // Programmatic Guaranteed
    --color-adgroup-type-programmatic-guaranteed: #{primaryColor(2)};

    // Toolbar Actions
    --color-toolbar-action-text: #{grayscaleColor(3)};

    // Card sizes
    --size-tile-width: #{$base-unit * 23}; // 184px: 'original' tile format, without tile details
    --size-tile-details-min-width: #{$base-unit * 28.5}; // 228px: 'new' tile format, with in-tile details

    // Icon Defaults
    --color-icon-none: magenta; // Dont use fill: none or stroke: none
    --color-icon-one: currentColor;
    --color-icon-two: currentColor;
    --color-icon-three: currentColor;
    --color-icon-four: currentColor;
    --color-icon-five: currentColor;
    --color-icon-six: currentColor;
    --color-icon-seven: currentColor;
    --color-icon-eight: currentColor;
    --color-icon-nine: currentColor;
    --color-icon-ten: currentColor;
    --color-icon-eleven: currentColor;
    --color-icon-twelve: currentColor;
    --color-icon-thirteen: currentColor;
    --color-icon-fourteen: currentColor;
    --color-icon-fifteen: currentColor;
    --color-icon-sixteen: currentColor;
    --color-icon-seventeen: currentColor;
    --color-icon-eighteen: currentColor;
    --color-icon-nineteen: currentColor;
    --color-icon-twenty: currentColor;
    --color-icon-twentyone: currentColor;
    --color-icon-twentytwo: currentColor;
    --color-icon-twentythree: currentColor;
    --color-icon-twentyfour: currentColor;
    --color-icon-twentyfive: currentColor;
    --color-icon-twentysix: currentColor;
    --color-icon-twentyseven: currentColor;
    --color-icon-twentyeight: currentColor;
}

/* stylelint-disable-next-line selector-max-universal */
*,
*:before,
*:after {
    box-sizing: inherit;
}

// You should not be using the standard HTML <button>.  We have a react <Button> component that wraps <button> and does a bunch
// of other specific styling, etc.
button {
    /* stylelint-disable-next-line color-no-hex, scale-unlimited/declaration-strict-value */
    background-color: #ff0090;

    /* stylelint-disable-next-line selector-no-qualifying-type */
    &.reset-styles {
        @include resetButtonStyles();
    }
}

@media print {
    // Setting an explicit `size` here is key. We must set this in order to disable
    // the "paper size" and "scaling" options within the print preview dialog. Those
    // features break our export, as certain features (e.g. Highcharts) can't reliably
    // resize/rerender when changes are made inside print preview. As such, they appear
    // broken. Please do not remove or modify this without extensively testing all
    // consumers of `ExportArea`.
    @page {
        size: 8.5in 11in;
        margin: 0.35in 0.4in;
    }

    // Our default reset stylesheet makes these elements `height: 100%;`. We don't
    // want that when printing, as it can sometimes lead to additional blank pages
    // being included.
    html,
    body {
        height: auto;
    }
}

/* stylelint-enable */
