$font-url: '/fonts';
@import 'component-core.scss';

.message-dialog {
    display: flex;
    flex-direction: column;
    max-height: 65%;

    .message-dialog__content {
        // This ensures that the content takes up the rest of the
        // room that the header and the footer didn't take up.
        flex-grow: 1;
        padding: 0 $size-m;
    }

    .message-dialog__image {
        margin-bottom: $size-s;
        height: 80px;
        width: 100%;
    }

    .message-dialog__title {
        @include pageTitleText();
        text-align: center;
        margin-bottom: $size-xs;
    }

    .message-dialog__message {
        @include bodyText();
        text-align: center;
        margin-bottom: $size-xxl;
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__export-code.scss';
    @import 'themes/theme__left-align.scss';
    @import 'themes/theme__line-break-title.scss';
    @import 'themes/theme__new-bidlist.scss';
    @import 'themes/theme__no-message-padding.scss';
}
