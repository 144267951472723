@mixin standardScrollbar($backgroundColor) {
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        background-color: $backgroundColor;
    }

    &::-webkit-scrollbar {
        width: 12px;
        background-color: transparent;
        margin-left: -18px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: grayscaleColor(3);
    }
}

@mixin dropdownMenuScrollbar() {
    @include standardScrollbar($color-drop-down-menu-background);

    margin-right: 8px;
}

// Custom-styled scrollbars for Anacapa's main content areas. Default width
// matches Firefox's default width, which cannot be changed.
@mixin anacapaBodyScrollbar($scrollbarWidth: 17px) {
    // The `scrollbar-*` properties are not widely supported, but include them
    // to make Firefox look better.
    // On Firefox on Windows, the default width is about 17px wide.
    scrollbar-width: auto;
    // Note: It would be tempting to use `transparent` instead of `grayscaleColor(2)`
    // below for the background color. Unfortunately, this also then makes the
    // scroll arrows at the top and bottom of the track invisible, too, which
    // looks super strange.
    scrollbar-color: grayscaleColor(4) grayscaleColor(2);

    &::-webkit-scrollbar {
        width: $scrollbarWidth;
    }

    &::-webkit-scrollbar-track {
        background: grayscaleColor(2);
    }

    &::-webkit-scrollbar-corner {
        background: grayscaleColor(2);
    }

    &::-webkit-scrollbar-thumb {
        background-color: grayscaleColor(4);
        border-radius: 9px;
        border: 3px solid grayscaleColor(2);
    }
}
