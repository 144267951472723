@mixin pageCard() {
    // Ensure that should an `.action-bar` appear, you can still scroll to see all content.
    margin-bottom: $size-xxxl;

    // Ensure that only themed card inherits min-height
    /* stylelint-disable-next-line selector-combinator-whitelist */
    > .card__inner {
        min-height: 400px; // per UX
    }
}
