$font-url: '/fonts';
@import 'component-core.scss';

.stepped-task-dialog {
    display: flex;
    flex-direction: column;

    .stepped-task-dialog__content {
        // This ensures that the content takes up
        // the rest of the room that the header and the footer didn't take up.
        flex-grow: 1;
        overflow-y: auto;
        padding: $size-m;

        // Does a similar (and redundant) thing as flex-grow: 1, but allows children to continue to use height: 100% if needed
        height: 100%;
    }

    .stepped-task-dialog__footer {
        height: $size-xxl;
        min-height: $size-xxl;
    }

    .stepped-task-dialog__steps {
        flex-grow: 1;
        margin: 0 $size-xxxl;
    }

    .stepped-task-dialog__steps--medium .step-status__steps {
        max-width: 384px;
    }

    .stepped-task-dialog__steps--large .step-status__steps {
        max-width: 696px;
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__first-run-wizard.scss';
}
