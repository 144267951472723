$font-url: '/fonts';
@import 'component-core.scss';

.indicator-component {
    display: inline-block;
    border-radius: 50%;
    position: relative;

    &--green {
        background: stoplightColor('green');

        &.indicator-component--disabled {
            background: stoplightColor('light-green');
        }
    }

    &--red {
        background: stoplightColor('red');

        &.indicator-component--disabled {
            background: stoplightColor('light-red');
        }
    }

    &--yellow {
        background: stoplightColor('yellow');
    }

    &--primary1 {
        background: primaryColor(1);
    }

    &--primary3tint1 {
        background: primaryTint(3, 1);
    }

    &--neutral {
        background: grayscaleColor(3);
    }

    .indicator-component__content {
        position: absolute;
        font-size: 0.65em;
        color: grayscaleColor(1);
        top: 0.15em;
    }
}
