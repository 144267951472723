
@include theme('site-toolbar') {
    &.drop-down-menu__main--open {
        .drop-down-menu-arrow {
            color: $color-site-toolbar-text;
        }
    }

    .drop-down-menu__label {
        @include siteToolbarText();
        opacity: 0.8;
        transition: opacity 0.05s linear;

        &:hover,
        &:focus {
            opacity: 1;
        }
    }
}
