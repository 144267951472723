// we need the button to not add any sort of margin or padding
@include theme('icon-only') {
  &.button--flat {
      padding: 0;
      height: auto;
  }

  .button__content {
    margin: $size-xxs; // default margin, but can be reset by just adjusting the margin of the icon itself
  }
}
