@include theme('bid-list-adjustment-type') {
    .horizontal-list .horizontal-list__item {
        // "Target"-style radiobutton
        &:first-of-type {
            .radio-control--enabled {
                .radio-control__label--selected,
                .radio-control__label--selected:active {
                    color: stoplightColor('green');
                    background: stoplightColor('light-green');
                    border-color: stoplightColor('green');
                }

                /* stylelint-disable-next-line selector-combinator-whitelist */
                .radio-control__input:focus + .radio-control__label--not-selected,
                .radio-control__label--not-selected:hover {
                    border-color: stoplightColor('green');
                }
            }
        }

        // "Block"-style radiobutton
        &:last-of-type {
            .radio-control--enabled {
                .radio-control__label--selected,
                .radio-control__label--selected:active {
                    color: stoplightColor('red');
                    background: stoplightColor('light-red');
                    border-color: stoplightColor('red');
                }

                /* stylelint-disable-next-line selector-combinator-whitelist */
                .radio-control__input:focus + .radio-control__label--not-selected,
                .radio-control__label--not-selected:hover {
                    border-color: stoplightColor('red');
                }
            }
        }
    }
}
