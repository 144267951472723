@include theme('action-navigation-primary-right-toolbar') {
    height: unset;
    max-height: 48px;
    padding: 8px 2px;
    width: 76px;
    box-sizing: content-box;
    border: solid 1px primaryColor(3); // Deep reef

    &.button--enabled:hover,
    &.button--enabled:focus,
    &.button--enabled:active {
        --txt-color: #{grayscaleColor(1)};
        .button__background {
            --bg-color: #{primaryColor(3)}; // Deep reef
            --opacity: 1;
            border-radius: 0;
        }
    }
}
