$font-url: '/fonts';
@import 'component-core.scss';

.ac-filter-label {
    @include resetButtonStyles();
    background-color: var(--ac-background, #{grayscaleColor(1)});
    padding: var(--ac-padding, 8px 16px);
    border-radius: 16px;
    border: var(--ac-border, 1px solid #{grayscaleColor(5)});
    outline: none;
    user-select: none;
    cursor: pointer;
    font-size: 14px;

    &:hover,
    &:focus {
        --ac-border: 1px solid #{grayscaleColor(7)};
        --ac-background: #{grayscaleColor(2)};
    }

    &.ac-filter-label--with-value {
        --ac-border: 2px solid #{grayscaleColor(7)};
        --ac-padding: 7px 15px;
        --ac-background: #{grayscaleColor(2)};
    }

    &.ac-filter-label--open {
        --ac-border: 1px solid #{grayscaleColor(5)};
        --ac-padding: 8px 16px;
        --ac-background: #{primaryTint(1, 5)};

        &:focus,
        &:hover {
            --ac-background: #{primaryTint(1, 5)};
        }
    }
}
