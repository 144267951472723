$font-url: '/fonts';
@import 'component-core.scss';

.radio-control {
    $radio-size: 16px;
    $radio-dot-size: 3px;

    position: relative;
    margin-bottom: $size-xxs;

    .radio-control__input {
        position: absolute;
        left: -9999px;
    }

    .radio-control__label {
        @include textStyle($size: 14px, $color: $color-input-text);
        position: relative;
        height: $size-xs;
        padding-left: $size-s;
        cursor: pointer;
        user-select: none;
        white-space: nowrap;

        // The actual radio control is visually represented via psuedo elements.
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: $radio-size;
            height: $radio-size;
            border-radius: 50%;
            border: 1px solid;
            border-color: grayscaleColor(4);
            background-color: grayscaleColor(1);
            transition: border-color 0.15s, color 0.1s, background-color 0.1s;
        }

        &:after {
            content: '';
            position: absolute;
            left: $radio-size / 2 - $radio-dot-size;
            top: $radio-size / 2 - $radio-dot-size;
            height: $radio-dot-size;
            width: $radio-dot-size;
            border-radius: $radio-dot-size;
            border: $radio-dot-size solid transparent;
        }
    }

    // When a radio is hovered, highlight it.
    /* stylelint-disable-next-line selector-combinator-whitelist */
    .radio-control__input:focus + .radio-control__label--enabled.radio-control__label--not-selected,
    .radio-control__label--enabled.radio-control__label--not-selected:hover {
        &:before {
            border-color: primaryColor(1);
        }
    }

    // Make enabled radios brand colors.
    .radio-control__label--enabled.radio-control__label--selected {
        &:before {
            border: 1px solid primaryColor(1);
            background-color: primaryTint(1, 5);
            box-shadow: inset 0 0 1px 0 primaryColor(1);
        }

        &:after {
            border-color: primaryColor(1);
            box-shadow: 0 0 1px 0 primaryColor(1);
        }
    }

    // Make disabled radios grayscale.
    .radio-control__label--disabled {
        color: $color-input-disabled-text;
        cursor: default;

        &:before {
            border-color: grayscaleColor(4);
            background-color: grayscaleColor(2);
        }

        &.radio-control__label--selected:after {
            border-color: grayscaleColor(4);
        }
    }
}
