@include theme('light') {
    @include shadow(4);

    $lightColor: grayscaleColor(1);

    background-color: $lightColor;
    border-radius: size-from-base(1 / 4);
    color: grayscaleColor(6);

    &.tooltip--top {
        .tooltip__arrow {
            border-top-color: $lightColor;
        }
    }

    &.tooltip--bottom {
        .tooltip__arrow {
            border-bottom-color: $lightColor;
        }
    }

    &.tooltip--right {
        .tooltip__arrow {
            border-right-color: $lightColor;
        }
    }

    &.tooltip--left {
        .tooltip__arrow {
            border-left-color: $lightColor;
        }
    }
}
