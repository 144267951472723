@include theme('bulk-edit-names') {
    .labelled-input__label {
        margin: 0;
        width: 44 * $base-unit; // 352px, arbitrary, from UX
        word-break: break-word;
    }

    .labelled-input__input {
        align-items: center;
        display: flex;
    }
}
