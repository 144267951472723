@include theme('wizard-koa-options') {
    .horizontal-list__item {
        margin: $size-xxs;

        // Extra specificity needed to override margin-left applied by HorizontalList
        /* stylelint-disable-next-line selector-combinator-whitelist */
        + .horizontal-list__item {
            margin: $size-xxs;
        }
    }
}
