$font-url: '/fonts';
@import 'component-core.scss';

.checkbox-group {
    margin-bottom: 8px;
}

.checkbox-group-grid {
    @for $i from 1 through 12 {
        &.checkbox-group-grid--size-#{$i} {
            grid-template-columns: repeat(#{$i}, minmax(auto, 1fr));
        }
    }

    display: grid;
    grid-gap: $size-xs;
    margin-bottom: $size-xxs;
}
