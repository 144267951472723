// Within this file, enable direct styling of HTML elements.
/* stylelint-disable selector-max-attribute, selector-no-qualifying-type, order/order */

@mixin inputStyle() {
    @include placeholderStyle();
    @include textFont($weight: 'Normal');
    width: 100%;
    height: $single-line-input-height;
    padding: 0 8px;
    border: 1px solid $color-input-border;
    color: $color-input-text;
    // relative positioning required to create a new stacking context to properly display the outline on focus/hover
    position: relative;

    &:focus,
    &:focus-within,
    &:hover:not([disabled]) {
        outline: 1px solid $color-input-border-focus;
        outline-offset: 0;
        z-index: $zindex-active;
    }

    &[disabled] {
        background-color: $color-input-disabled-background;
        color: $color-input-disabled-text;
        opacity: 0.75;
    }
}

@mixin placeholderStyle() {
    &::placeholder {
        @include textStyle($color: $color-input-place-holder);
        transition: opacity 0.1s ease-in-out;
    }

    &:focus::placeholder {
        color: $color-input-place-holder-focus;
    }
}

@mixin standardInput($inputClassPrefix: 'input') {
    min-height: $single-line-input-height;

    input {
        @include inputStyle();
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    input:focus {
        text-overflow: unset;
    }

    &.#{$inputClassPrefix}--error:not(.#{$inputClassPrefix}--inline) {
        input {
            border: $color-validation-error-border solid 1px;
            outline: none;

            &:focus,
            &:hover {
                box-shadow: 0 0 0 1px rgba($color-validation-error-border-rgb, 0.3);
                outline: none;
            }
        }
    }

    &.#{$inputClassPrefix}--inline,
    &.#{$inputClassPrefix}--inline:hover {
        min-height: $single-line-input-height;
        position: relative;
        width: 100%;

        &.#{$inputClassPrefix}--error input,
        &.#{$inputClassPrefix}--error {
            border-color: $color-validation-error-border;

            &:hover,
            &:focus {
                border-bottom-color: $color-validation-error-border;
                outline: none;
            }
        }

        input {
            @include inlineInput();
            padding: 0;

            &[disabled] {
                border-bottom-width: 0;
                opacity: 1;
            }
        }

        input.#{$inputClassPrefix}--error {
            border-bottom-color: $color-validation-error-border;

            &:hover,
            &:focus {
                border-bottom-color: $color-validation-error-border;
                outline: none;
            }
        }

        .validationerror--inline {
            position: absolute;
            right: -24px;
            top: 2px;
        }
    }

    &.#{$inputClassPrefix}--right-align {
        input,
        span,
        div {
            text-align: right;
        }
    }
}

@mixin readOnlyInput($color: grayscaleColor(6)) {
    color: $color;
    line-height: $single-line-input-height;
}

@mixin inputError() {
    border-color: $color-validation-error-border;
    border-width: 1px;
}

@mixin inlineInput() {
    height: $single-line-input-height - 8px;
    margin-top: 4px; // this will align the input the middle of the single-line-input-height
    border-width: 0;
    background: transparent;
    border-bottom-width: 1px;
    transition: all 0.1s ease-in;

    &:hover:not([disabled]):not(:focus) {
        box-shadow: none;
        outline-width: 0;
    }

    &:focus,
    &:focus-within {
        box-shadow: none;
        outline-width: 0;
        border-width: 0;
        border-bottom-width: 1px;
        border-bottom-color: $color-input-inline-border-focus;
    }

    &[disabled] {
        background-color: transparent;
        color: $color-input-disabled-text;
    }
}

/* stylelint-enable */
