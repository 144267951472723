$font-url: '/fonts';
@import 'component-core.scss';


$drop-down-menu-background: grayscaleColor(1) !default;
$drop-down-menu-color: grayscaleColor(7) !default;
$drop-down-menu-light: grayscaleColor(1) !default;

.drop-down-menu__main {
    $top: 26px;

    position: relative;
    cursor: pointer;
    display: inline-block;

    &--portal {
        position: absolute !important;
    }

    &.drop-down-menu__main--disabled {
        .drop-down-menu__label.drop-down-menu__label--disabled,
        .drop-down-menu__label.drop-down-menu__label--disabled:hover,
        .drop-down-menu__label.drop-down-menu__label--disabled:focus {
            opacity: 0.75;
            cursor: default;
        }
    }

    &--open {
        .drop-down-menu-arrow {
            color: $color-control-basic;
        }
    }

    /* stylelint-disable-next-line selector-combinator-whitelist */
    &--portal + &--align-none .drop-down-menu__menu {
        top: $top;
    }

    &--align-right .drop-down-menu__menu {
        right: 0;
        left: auto;
        top: $top;
    }

    &--align-left .drop-down-menu__menu {
        left: 0;
        right: auto;
        top: $top;
    }

    .drop-down-menu__label {
        @include dropDownMenuText();
        padding: 8px;
        outline: none;

        position: relative;
        display: flex;
        align-items: center;

        .drop-down-menu__label-text {
            flex: 1;
        }

        .drop-down-menu-arrow.glyph {
            font-size: 8px;
            margin-left: 8px;
        }
    }

    .drop-down-menu__menu {
        @include shadow(1);
        position: absolute;
        background: $color-drop-down-menu-background;
        color: $color-drop-down-menu;
        opacity: 1;
    }

    .drop-down-menu__menu--long .drop-down-menu__list {
        @include dropdownMenuScrollbar();
    }

    .drop-down-menu__list {
        list-style-type: none;
        padding: 2px 0;
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__data-hub-actions.scss';
    @import 'themes/theme__data-view-toolbar.scss';
    @import 'themes/theme__error-border.scss';
    @import 'themes/theme__flight-dropdown.scss';
    @import 'themes/theme__multi-select-filters.scss';
    @import 'themes/theme__single-select-filters.scss';
    @import 'themes/theme__site-toolbar-user-menu.scss';
    @import 'themes/theme__site-toolbar.scss';
    @import 'themes/theme__time-control.scss';
    @import 'themes/theme__toolbar-action.scss';
    @import 'themes/theme__top-seller-media-type.scss';
    @import 'themes/theme__drop-down-panel.scss';
    @import 'themes/theme__filter.scss';
}
