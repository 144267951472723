$font-url: '/fonts';
@import 'component-core.scss';

.basic-dialog {
    min-height: size-from-base(32); // 256px;
    margin-top: 0;
    transition: margin-top 0.4s ease-in-out, bottom 0.4s ease-in-out;

    &.dialog-core__dialog--transitioning {
        // Arbitrary; large enough to ensure content is entirely offscreen.
        margin-top: -2000px;

        &.basic-dialog--large,
        &.basic-dialog--extra-large {
            // Arbitrary; large enough to ensure content is entirely offscreen.
            bottom: 2000px;
        }
    }

    &.basic-dialog--small {
        width: size-from-base(50); // 400px;

        .basic-dialog__content {
            max-height: size-from-base(75); // 600px;
        }
    }

    &.basic-dialog--medium {
        width: size-from-base(100); // 800px;

        .basic-dialog__content {
            max-height: size-from-base(75); // 600px;
        }
    }

    &.basic-dialog--large {
        width: size-from-base(138); // 1104px;
        bottom: size-from-base(4); // 32px;
    }

    &.basic-dialog--extra-large {
        width: size-from-base(163); // 1304px;
        bottom: size-from-base(4); // 32px;
    }

    .basic-dialog__content {
        overflow: auto;
    }
}
