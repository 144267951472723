$font-url: '/fonts';
@import 'component-core.scss';

.task-dialog {
    display: flex;
    flex-direction: column;

    .task-dialog__content {
        // This ensures that the content takes up
        // the rest of the room that the header and the footer didn't take up.
        flex-grow: 1;
        padding: $size-m;
    }

    .task-dialog__footer {
        height: $size-xxl;
        min-height: $size-xxl;
        max-height: $size-xxl;
    }

    .task-dialog__footer--hidden {
        display: none;
    }

    .task-dialog__page {
        height: 100%;
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__copy-paste.scss';
    @import 'themes/theme__create-datahub-list.scss';
    @import 'themes/theme__daily-pacing-chart.scss';
    @import 'themes/theme__data-group-browse.scss';
    @import 'themes/theme__export-code.scss';
    @import 'themes/theme__new-pg-campaign.scss';
    @import 'themes/theme__private-contract-browse.scss';
}
