$font-url: '/fonts';
@import 'component-core.scss';

.data-filters-container {
    width: 100%;
    margin-bottom: 8px;
    display: flex;
    height: 32px;

    .data-filters-container__clear-filters {
        opacity: 0;
        pointer-events: none;
        margin-left: -$size-xxs;
        margin-top: 1px; // this so it aligns with the items in data-filters-container__right-filters

        &.data-filters-container__clear-filters--visible {
            opacity: 1;
            pointer-events: all;
        }
    }

    .data-filters-container__left {
        display: flex;
        margin-right: 20px;
        flex-grow: 1;
        align-items: baseline;

        .data-filters-item {
            margin-right: $size-xs;
        }
    }

    .data-filters-container__right {
        margin-left: 20px;

        .data-filters-container__right-filters {
            display: flex;
            flex-grow: 0;
            justify-content: flex-end;
            align-items: baseline;
        }

        .data-filters-item {
            margin-left: $size-xs;
        }
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__full-width-search.scss';
    @import 'themes/theme__no-left-filters.scss';
}
