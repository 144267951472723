// Defines the z-indexes used throughout the application.
// Note: this file is also consumed in JS by our components via `sass-variable-loader`.

// For more information about z-indexes and stacking contexts, visit:
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context

// Within a component, certain elements may need to be displayed on top of their
// neighbors (e.g. fixed table columns,
$zindex-default: 0;
$zindex-promoted: $zindex-default + 1;

// Adjacent components may have overlapping borders. To ensure they visually
// stack correctly, we promote active and focused controls.
// Note that when applying this active index (e.g. on hover of a `SwitchControl`),
// `position: relative` may be required to create a new stacking context (if one does
// not already exist for the specific context).
$zindex-active: 1;

// Default index for the fixed-position action bar. Used as `zIndexes.ActionBar` in
// `ActionBarContainer.js`.
$zindex-action-bar: 1;

// Index for sticky page header (also referred to as `core-layout__toolbar`).
// Should appear above page contents.
$zindex-page-header: 5;

// Anacapa's left+right navs needs to appear above promoted content (e.g.
// button text), but beneath dropdowns.
$zindex-anacapa-frame: 2;

// Default index for dropdowns. Used as `zIndexes.Dropdown` in `DropDownMenuContainer.js`.
$zindex-dropdown: 3;

// Dialogs. Used as `zIndexes.Dialog` in `DialogReducer.js`.
$zindex-dialog: 50;

// Certain components appears above nearly all others on the page.
$zindex-toastie: 100;
$zindex-help: 125;
$zindex-tooltip: 150;
$zindex-callout: 150;
