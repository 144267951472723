@import '_fill-space';

@include theme('targeting-tile-with-details') {
    @include fillSpace();

    .card__inner {
        // Override the theme's padding
        padding: $card-padding;

        // Override card's default.
        min-height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
