@include theme('error-border') {
    &:focus-within,
    &:hover {
        box-shadow: 0 0 0 1px rgba($color-validation-error-border-rgb, 0.3);
    }

    .drop-down-menu__label {
        &.drop-down-menu__label--closed {
            border: $color-validation-error-border solid 1px;
            outline: none;
        }
    }
}
