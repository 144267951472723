$font-url: '/fonts';
@import 'component-core.scss';

.datatable.datatable--light {
    // This is actually *very* difficult to discern on some of my
    // monitors, and easier on others, but it is what the UX team
    // asked for so we'll keep it.
    $hoverColor: primaryTint(1, 5);
    $selectionColor: primaryTint(1, 5);
    $borderColor: grayscaleColor(3);
    $border: 1px solid $borderColor;
    $headerFooterBorderColor: grayscaleColor(4);
    $headerFooterBorder: 1px solid $headerFooterBorderColor;
    $bottomRightCornerColor: grayscaleColor(5);
    $backgroundColor: grayscaleColor(1);
    background-color: $backgroundColor;
    border: $border;

    .datatable__header {
        background-color: grayscaleColor(2);

        a {
            @include textStyle(
                $isUppercase: true,
                $color: grayscaleColor(6),
                $size: 11px,
                $letterSpacing: 1px
            );
            width: 100%;
        }

        .datatable__column--delete {
            a {
                // override the letter-spacing to fix the off positioning of the delete's "x"
                letter-spacing: 0;
            }
        }

        .datatable__row {
            $header-height: 32px;
            height: $header-height;
            min-height: $header-height;
            max-height: $header-height;
            border-bottom: $border;

            .datatable__column {
                padding: 0 $size-xxs;
                line-height: $header-height;
                border-right: $headerFooterBorder;

                &--last {
                    padding: 0 12px 0 $size-xxs;
                    border-right: none;
                }

                &.datatable__column--sortable {
                    &:hover,
                    &:focus {
                        background-color: grayscaleColor(3);
                        outline: none;
                    }
                }
            }

            .datatable__row--spacer {
                border-top: $headerFooterBorder;
            }

            .datatable__column--sorted-asc,
            .datatable__column--sorted-desc {
                background-color: grayscaleColor(3);

                a {
                    color: grayscaleColor(7);
                }
            }
        }
    }

    .datatable__additional-header.datatable__row {
        $additional-header-height: $size-m;
        @include textStyle($color: grayscaleColor(7), $size: 11px);
        background-color: grayscaleColor(3);
        border: $border;
        height: $additional-header-height;
        min-height: $additional-header-height;
        max-height: $additional-header-height;

        .datatable__column {
            padding: 0 $size-xxs;
            line-height: $additional-header-height;
        }
    }

    .datatable__footer.datatable__row {
        $footer-height: $size-m;
        @include textStyle($color: grayscaleColor(6), $size: 11px);
        background-color: grayscaleColor(2);
        border: $border;
        height: $footer-height;
        min-height: $footer-height;
        max-height: $footer-height;

        &--spacer {
            border-top: $border;
        }

        .datatable__column {
            padding: 0 $size-xxs;
            line-height: $footer-height;
            border-right: $headerFooterBorder;

            &--last {
                border-right: none;
            }
        }
    }

    .datatable__row {
        .datatable__column {
            &.datatable__column--fixed {
                background-color: $backgroundColor;

                &:hover {
                    background-color: $hoverColor;
                }

                &.datatable__column--last-fixed {
                    border-right: $border;
                }
            }

            .datatable__column-content {
                width: 100%;
            }
        }
    }

    .datatable__body {
        @include textStyle($color: grayscaleColor(6), $size: 14px);

        &:focus {
            outline: 0;
        }

        .datatable__row {
            &--selected {
                background-color: $selectionColor;
            }

            &:hover {
                background-color: $hoverColor;
            }

            .datatable__column {
                padding: 4px $size-xxs;
                border-bottom: $border;
            }

            .datatable__column--last {
                padding: 4px 12px 4px $size-xxs;
            }
        }
    }

    .datatable__scrollbar__container {
        background-color: $bottomRightCornerColor;
    }
}
