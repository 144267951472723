$font-url: '/fonts';
@import 'component-core.scss';


.fixed-content-input:not(.fixed-content-input--inline) {
    height: $single-line-validation-message-height + $single-line-input-height;
}

.fixed-content-input--inline {
    .input-inline-validation {
        display: flex;
        align-items: center;

        // We can have myriad data rendered inline here and need to be specific about which
        // tooltip we're targeting.
        /* stylelint-disable-next-line selector-combinator-whitelist */
        > .tooltip__anchor {
            margin-left: $size-xxs;
        }
    }
}
