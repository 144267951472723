@include theme('single-select-filters') {
    // This needs to go here because SelectList overwrites these styles
    &.select-list {
        &.color-3 {
            .drop-down-menu__label {
                border-left: 4px solid primaryTint(3, 1);
            }
        }
    }
}
