$font-url: '/fonts';
@import 'component-core.scss';

.date-range-picker {
    display: inline-block;

    // If we're rendering validation inline, we want to leverage flex
    // to keep everything on the same line.
    &.date-range-picker--inline-validation {
        display: inline-flex;
    }

    .date-range-picker__input-wrapper {
        display: flex;
        align-items: center;
    }

    // Make the standard input appear as if it was a text input.
    .date-range-picker__input-wrapper--standard {
        &.date-range-picker__input-wrapper--active,
        &.date-range-picker__input-wrapper--disabled {
            @include inputStyle();
            background: grayscaleColor(1);
        }
    }

    .date-range-picker__divider {
        // Don't show selector cursor over divider.
        cursor: default;
    }

    .date-range-picker__divider--inline {
        color: grayscaleColor(5);
        padding: 0 $size-xxs;
    }

    .date-range-picker__validation--inline {
        display: inline-flex;
        align-self: center;

        // Space the validation message out a bit.
        .date-range-picker__validation {
            margin-left: 6px;
        }

        // Space out validation errors a bit.
        .date-range-picker__validator {
            margin-left: 4px;

            // Sometimes validators are included on the page, even when
            // no errors are present. Hide those.
            &:empty {
                display: none;
            }
        }
    }

    // Here and below is the popout picker itself.
    .date-range-picker__picker-wrapper {
        position: absolute;
        margin-top: 4px;
        z-index: $zindex-active;
        align-self: flex-end;
    }

    .date-range-picker__picker {
        position: absolute;
        transition: opacity 0.1s;
    }

    .date-range-picker__picker--closed {
        opacity: 0;
        pointer-events: none;
    }

    .date-range-picker__picker--open {
        // Give the popup a significant margin bottom to ensure that, if it appears
        // near the bottom of the page, it isn't obscured by the action bar.
        margin-bottom: $size-xxxl;
        opacity: 1;
        pointer-events: all;
        z-index: $zindex-dropdown;
    }

    .date-range-picker__additional-things {
        display: flex;
        padding: 0 $size-s $size-s $size-s;
    }

    .date-range-picker__additional-control {
        flex-basis: 50%;

        // Make a `$size-s` gutter between the two controls.
        /* stylelint-disable-next-line selector-combinator-whitelist */
        + .date-range-picker__additional-control {
            margin-left: $size-s;
        }
    }

    // If the "end date" checkbox is displayed below a time control,
    // give it a bit of extra spacing.
    /* stylelint-disable-next-line selector-combinator-whitelist */
    .labelled-input + .date-range-picker__end-date-optional {
        margin-top: $size-xxs;
    }

    // Validation: todo
    .date-range-picker__validation {
        display: flex;
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__campaign-flights.scss';
    @import 'themes/theme__date-range-filter.scss';
}
