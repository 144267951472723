$font-url: '/fonts';
@import 'component-core.scss';

.date-picker {
    position: relative;

    // Container for the popout calendar.
    .date-picker__picker-wrapper {
        position: absolute;
    }

    // The popout picker itself.
    .date-picker__picker {
        position: absolute;
        top: 4px;
        transition: opacity 0.1s;
        z-index: $zindex-active;

        &:focus {
            outline: none;
        }
    }

    .date-picker__picker--closed {
        opacity: 0;
        pointer-events: none;
    }

    .date-picker__picker--open {
        opacity: 1;
        pointer-events: all;
        z-index: $zindex-dropdown;
    }

    .date-picker__time {
        margin: 0 $size-s $size-s $size-s;
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__date-picker-filter.scss';
}
