@include theme('action-navigation-secondary') {
    height: 16px;
    padding: 6px 4px;
    box-sizing: content-box;

    .button__content {
        display: unset;
        align-items: unset;
        justify-content: unset;
    }
}
