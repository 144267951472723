@include theme('close-button') {
    color: grayscaleColor(5);

    &:hover,
    &:focus {
        color: grayscaleColor(7);
    }

    // Create gutter around glyph to increase the clickable area.
    .glyph__inner {
        padding: $size-xxs / 2;
    }
}
