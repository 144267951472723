$font-url: '/fonts';
@import 'component-core.scss';

.checkbox-control {
    $checkbox-size: 16px;
    margin-bottom: 8px;
    position: relative;

    .checkbox-control__input {
        position: absolute;
        left: -9999px;
    }

    .checkbox-control__label {
        @include textStyle($size: 14px, $color: $color-input-text);
        position: relative;
        padding-left: 24px;
        cursor: pointer;
        user-select: none;

        // The checkmark box.
        &:before {
            content: '';
            position: absolute;
            left: 0;
            // Vertically center within label
            top: calc(50% - #{$checkbox-size} / 2);
            width: $checkbox-size;
            height: $checkbox-size;
            border: 1px solid grayscaleColor(4);
            background-color: grayscaleColor(1);
            transition: all 0.1s;
        }

        // The checkmark itself.
        &:after {
            content: '';
            position: absolute;
            left: 4px;
            top: calc(50% - 3px);
            width: 8px;
            height: 4px;
            border-bottom: 1px solid grayscaleColor(1);
            border-left: 1px solid grayscaleColor(1);
            opacity: 0;
            transition: opacity 0.1s;
            transform: rotate(-50deg) scale(1.3);
        }
    }

    // Indeterminate version
    .checkbox-control__label--indeterminate {
        &:before {
            background-color: grayscaleColor(1);
            border-color: primaryColor(1);
            border-width: 2px;
        }

        &:after {
            content: '';
            position: absolute;
            left: 4px;
            top: calc(50% - 1px);
            width: 8px;
            height: 1px;
            border-bottom: 2px solid primaryColor(1);
            opacity: 1;
            transform: none;
        }
    }

    // Focus/hover state
    /* stylelint-disable-next-line selector-combinator-whitelist */
    .checkbox-control__input:focus + .checkbox-control__label:before,
    .checkbox-control__label--enabled:hover:before {
        border-color: primaryColor(1);
    }

    // Checked
    .checkbox-control__label--checked {
        &:before {
            background-color: primaryColor(1);
            border-color: primaryColor(1);
        }

        &:after {
            opacity: 1;
        }
    }

    // Disabled
    .checkbox-control__label--disabled {
        color: $color-input-disabled-text;
        cursor: default;

        &:before {
            border-color: grayscaleColor(4);
            background-color: grayscaleColor(2);
        }

        &:after {
            border-color: grayscaleColor(4);
        }
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__filter.scss';
}
