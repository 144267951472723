@include theme('currency-input') {
    &.fixed-content-control--readonly,
    &.fixed-content-control--readonly:hover {
        // We want the currency symbol to be flush with the
        // amount when the control is displayed as readonly;
        // as such, remove the inherited border.
        .fixed-content-control__fixed-content {
            margin-right: 0;
        }
    }
}
