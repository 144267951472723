$font-url: '/fonts';
@import 'component-core.scss';

$border-radius: 15px;

.percent-bar {
    min-width: 1px;
    min-height: 24px;
    background-color: grayscaleColor(2);
    position: relative;
    border-radius: $border-radius;
    overflow: hidden;

    // Generates all background color modifiers for every primary color.
    // Example: .percent-bar__background--primary-1, .percent-bar__background--primary-2, etc...
    /* stylelint-disable-next-line order/order */
    @each $i in $primary-palette-numbers {
        &.percent-bar__background--primary-#{$i} {
            background-color: primaryColor($i);
        }
    }

    &.percent-bar--animated .percent-bar__meter {
        transition: width 1s ease-out;
    }

    .percent-bar__meter {
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        // Generates all meter modifiers for every primary color.
        // Example: .percent-bar__meter--primary-1, .percent-bar__meter--primary-2, etc...
        /* stylelint-disable-next-line order/order */
        @each $i in $primary-palette-numbers {
            &.percent-bar__meter--primary-#{$i} {
                background-color: primaryColor($i);
            }
        }
    }
}
