@import '_fill-space';

@include theme('fill-space') {
    @include fillSpace();

    .card__inner {
        // Override card's default.
        min-height: auto;
    }
}
