$font-url: '/fonts';
@import 'component-core.scss';

.ac-filters {
    display: flex;
    justify-content: flex-end;
}

.ac-filter__drop-down {
    border-radius: 16px;
}
