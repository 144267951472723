@include theme('private-contract-marketplace-tile') {
    .card__inner {
        background-color: #f9f9f9;
        // Ensure tile consumes all available space.
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // Override card's default
        min-height: auto;
        padding: 0;
    }
}
