$font-url: '/fonts';
@import 'component-core.scss';

.drop-down-menu-item {
    @include dropDownMenuText();
    height: $select-list-item-height;
    background-color: grayscaleColor(1);
    transition: background-color 0.05s linear;

    &.drop-down-menu-item--enabled {
        &:hover,
        &.drop-down-menu-item--active,
        &:active {
            cursor: pointer;
            background-color: primaryTint(1, 5);
        }
    }

    &.drop-down-menu-item--disabled {
        cursor: default;
    }

    a {
        @include dropDownMenuText();
        text-decoration: none;
    }

    .glyph {
        display: inline-block;
        margin-right: 8px;
    }

    .drop-down-menu-item__label-content {
        white-space: nowrap;
        line-height: 32px;

        // This gnarly selector should have never been written, but we gotta live with it now.
        /* stylelint-disable-next-line selector-combinator-whitelist */
        > :first-child {
            text-align: left;
            overflow: hidden;
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: 0 24px;
        }
    }

    @import 'themes/theme__disabled.scss';
    @import 'themes/theme__header.scss';
    @import 'themes/theme__callouts.scss';
    @import 'themes/theme__audience-search.scss';
}
