$font-url: '/fonts' !default;
$glyphDir: '#{$font-url}/glyphs';
@font-face {
	font-family: "glyph-font-brandsafety";
    	src: url("#{$glyphDir}glyph-font-brandsafety.eot?f60cb136-72fc-4da6-bb48-ba76a6382bda?#iefix") format("embedded-opentype"),
    		 url("#{$glyphDir}/glyph-font-brandsafety.woff?f60cb136-72fc-4da6-bb48-ba76a6382bda") format("woff"),
    		 url("#{$glyphDir}/glyph-font-brandsafety.ttf?f60cb136-72fc-4da6-bb48-ba76a6382bda") format("truetype");
}

.glyph-family-brandsafety {
	line-height: inherit;
}

.glyph-family-brandsafety:before {
	font-family: glyph-font-brandsafety !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}
.glyph-family-brandsafety:after {
	font-family: glyph-font-brandsafety !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}


.glyph-brandsafety-block:before {
    content: "\ea01";
}
.glyph-brandsafety-block-after:after {
    content: "\ea01";
}

.glyph-brandsafety-do-not-block:before {
    content: "\ea02";
}
.glyph-brandsafety-do-not-block-after:after {
    content: "\ea02";
}

.glyph-brandsafety-superblock:before {
    content: "\ea03";
}
.glyph-brandsafety-superblock-after:after {
    content: "\ea03";
}

