$font-url: '/fonts';
@import 'component-core.scss';


.horizontal-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    &.horizontal-list--allow-wrapping {
        flex-wrap: wrap;
    }

    &.horizontal-list--align-left {
        justify-content: flex-start;
    }

    &.horizontal-list--align-right {
        justify-content: flex-end;
    }

    &.horizontal-list--align-center {
        justify-content: center;
    }

    &.horizontal-list--align-justify {
        justify-content: space-between;
    }

    &.horizontal-list--valign-top {
        align-items: flex-start;
    }

    &.horizontal-list--valign-bottom {
        align-items: flex-end;
    }

    &.horizontal-list--valign-center {
        align-items: center;
    }

    &.horizontal-list--valign-baseline {
        align-items: baseline;
    }

    &.horizontal-list--valign-stretch {
        align-items: stretch;
    }

    $gutter-sizes: "xxs", "xs", "s", "m", "l", "xl", "xxl", "xxxl";
    /* stylelint-disable-next-line order/order */
    @for $i from 1 through length($gutter-sizes) {
        &.horizontal-list--gutter-#{nth($gutter-sizes, $i)} {
            /* stylelint-disable-next-line selector-combinator-whitelist */
            > .horizontal-list__item + .horizontal-list__item {
                // There wasn't an elegant way to re-use our `sizes` variables here (e.g. `size-xs`), so
                // re-calculating those values manually.
                margin-left: #{$i * $base-unit};
            }
        }
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__wizard-koa-options.scss';
}

.horizontal-list__item {
    flex-grow: 0;
}
