$font-url: '/fonts';
@import 'component-core.scss';


.text-search-input-overlay {
    width: 100%;
    display: flex;

    .text-search-input-container {
        flex: 1;
        margin-bottom: 8px;
        display: flex;
        height: 28px;
    }

    .text-search-input-container__search-box {
        width: 100%;
        flex-grow: 1;

        input {
            @include placeholderStyle();
        }
    }

    .text-search-input-container__search-box--inline {
        border-bottom-color: transparent;
    }

    .text-search-input-container__search-clear {
        visibility: hidden;
        width: 32px;
        padding: 4px 10px 0 10px;

        &.search-field-active {
            visibility: visible;

            &.text-search-input-container__search-clear--without-select {
                padding-right: 0;
            }

            .glyph-family-default:before {
                font-size: 12px;
            }
        }
    }

    .text-search-input-container__fields-select {
        height: 24px;
        margin-top: 4px;
        display: none;

        &.search-field-active {
            display: block;
        }

        .select-list {
            width: auto;
            height: 24px;

            &.drop-down-menu__main {
                .drop-down-menu__label {
                    padding: 4px;
                    height: 24px;
                }

                .drop-down-menu__label-text {
                    width: 100%;
                    flex: 1;
                }

                .drop-down-menu__menu {
                    top: 24px;
                }
            }
        }
    }
}
