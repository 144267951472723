$font-url: '/fonts';
@import 'component-core.scss';

$triangle-height: 8px;
$tooltip-margin-width: 8px;
$tooltip-side-padding: $size-xs;

.tooltip {
    @include textStyle($size: 12px, $color: $color-tooltip-text);
    padding: 10px $tooltip-side-padding;
    max-width: 750px;
    opacity: 1;
    transition: opacity 0.1s linear; // Adds a quick fade-in effect.
    z-index: $zindex-tooltip; // Makes sure tooltips are in top of everything.
    word-wrap: break-word;

    // Default Style
    background-color: $color-tooltip-background-default;

    .tooltip__arrow {
        width: 0;
        height: 0;
        position: absolute;
        border-style: solid;
        border-color: $color-tooltip-background-default;
    }

    // Other Modifiers
    &.tooltip--hidden {
        opacity: 0;
    }

    // Arrow styles for the four different tooltip placements. [top - right - bottom - left]
    &.tooltip--top {
        margin-bottom: $triangle-height;

        .tooltip__arrow {
            border-left: $triangle-height solid transparent;
            border-right: $triangle-height solid transparent;
            border-top-width: $triangle-height;
            border-bottom: 0 solid transparent;
            bottom: -$triangle-height;
        }
    }

    &.tooltip--bottom {
        margin-top: $triangle-height;

        .tooltip__arrow {
            border-left: $triangle-height solid transparent;
            border-right: $triangle-height solid transparent;
            border-bottom-width: $triangle-height;
            border-top: 0 solid transparent;
            top: -$triangle-height;
        }
    }

    &.tooltip--right {
        margin-left: $triangle-height;

        .tooltip__arrow {
            border-top: $triangle-height solid transparent;
            border-bottom: $triangle-height solid transparent;
            border-right-width: $triangle-height;
            border-left: 0 solid transparent;
            left: -$triangle-height;
        }
    }

    &.tooltip--left {
        margin-right: $triangle-height;

        .tooltip__arrow {
            border-top: $triangle-height solid transparent;
            border-bottom: $triangle-height solid transparent;
            border-left-width: $triangle-height;
            border-right: 0 solid transparent;
            right: -$triangle-height;
        }
    }

    &.tooltip--preserve-whitespace {
        // We'd like to preserve the whitespace as specified by the author, but we
        // don't want lines poking out of the containing tooltip element.
        white-space: pre-wrap;
    }

    // Prepares styles for lists that are immediate children of tooltips. We need an element
    // selector here to reliably target the content children of this component.
    /* stylelint-disable-next-line selector-max-type, selector-combinator-whitelist */
    > ul {
        list-style-position: outside;
        padding: 0;
        margin: 0 0 0 $tooltip-side-padding;
    }

    @import 'themes/theme__error';
    @import 'themes/theme__light';
}

.tooltip__anchor {
    &.tooltip__anchor--inline {
        display: inline-block;
    }
}
