$font-url: '/fonts';
@import 'component-core.scss';

.toggle-input {
    min-height: $single-line-input-height + $single-line-validation-message-height;

    &.toggle-input--inline {
        min-height: unset;
    }

    .toggle-input--error {
        border-color: $color-validation-error-border;
        border-width: 1px;
    }
}
