$font-url: '/fonts';
@import 'component-core.scss';

.dialog-header {
    // Note: keep this value in sync with `$header-height` in `Panel.scss`.
    $headerHeight: $size-xxl;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color-dialog-header-background;
    height: $headerHeight;
    min-height: $headerHeight;
    padding: 0 $size-xs 0 32px;

    &.dialog-header--light {
        background-color: transparent;

        .dialog-header__titles,
        .dialog-header__title {
            color: grayscaleColor(7);
        }

        .dialog-header__subtitle {
            color: grayscaleColor(6);
        }

        .dialog-header__close {
            color: grayscaleColor(5);

            &:hover,
            &:focus {
                color: grayscaleColor(7);
            }
        }
    }

    &.dialog-header--subtitle-underneath {
        height: $headerHeight + $size-xs;

        .dialog-header__title {
            @include singleLineTextWithEllipsis();
            display: block;
            padding-top: 20px;
        }

        .dialog-header__subtitle {
            @include singleLineTextWithEllipsis();
            display: block;
            padding-left: 0;
        }
    }

    .dialog-header__tooltip-anchor {
        overflow: hidden;
    }

    .dialog-header__titles {
        @include textStyle(
            $size: 20px,
            $weight: 'Medium',
            $color: $color-dialog-header-text);
        @include singleLineTextWithEllipsis();
    }

    .dialog-header__title {
        @include pageTitleText($color-dialog-header-text);
    }

    .dialog-header__subtitle {
        @include textStyle(
            $size: 14px,
            $weight: 'Medium',
            $color: $color-dialog-header-text);
        padding-left: $size-xs;
    }

    .dialog-header__close {
        @include glyphStyle($size-xs, $color-dialog-header-text);
        cursor: pointer;
        // Padding added to increase the click target.
        margin-right: -5px;
        padding: 10px;
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__first-run-wizard.scss';
    @import 'themes/theme__panel-header.scss';
    @import 'themes/theme__transparent-header.scss';
}

.dialog-header-tooltip {
    .dialog-header-tooltip__subtitle {
        padding-left: $size-xs;
        color: grayscaleColor(4);
    }
}
