$font-url: '/fonts';
@import 'component-core.scss';

.date-picker-calendar-overrides {
    /* Overrides for the Airbnb date picker control. We can't control their classnames, so disable linter. */
    /* stylelint-disable selector-class-pattern  */
    .DayPicker_weekHeader_li {
        color: grayscaleColor(5);
    }

    .DayPickerNavigation_button__default {
        border: transparent;
    }

    .CalendarDay__default {
        border: 1px solid grayscaleColor(1);
        color: grayscaleColor(6);

        &:hover {
            @include primaryShadeBackgroundWithText(3, 1);
            border: 1px solid grayscaleColor(1);
        }
    }

    .CalendarDay__highlighted_calendar {
        @include primaryTintBackgroundWithText(3, 5);
    }

    .CalendarDay__selected_span {
        @include primaryColorBackgroundWithText(3);
    }

    .CalendarMonth_caption {
        color: grayscaleColor(7);

        strong {
            @include textStyle($weight: 'Heavy', $size: 18px);
        }
    }

    .CalendarDay__selected,
    .CalendarDay__selected_start {
        @include primaryShadeBackgroundWithText(3, 1);

        &:hover {
            @include primaryColorBackgroundWithText(3);
            border: 1px solid grayscaleColor(1);
        }
    }

    .CalendarDay__blocked_calendar.CalendarDay__default,
    .CalendarDay__blocked_calendar.CalendarDay__default:hover {
        background: grayscaleColor(2);
        color: grayscaleColor(5);
    }
    /* stylelint-enable */
}
