$font-url: '/fonts';
@import 'component-core.scss';

.date-time-text-input {
    @include standardInput('date-time-text-input');

    // For the standard control, we want to ensure that things are sized
    // to match the width of the input.
    &.date-time-text-input--standard {
        &.date-time-text-input--with-time {
            // This is hardcoded to match the width of the MM/DD/YYYY HH:MM PM format.
            // This is bad, but it's unclear what would break if it got removed.
            width: 158px;
        }

        &.date-time-text-input--without-time {
            // This is hardcoded to match the width of the MM/DD/YYYY format.
            // This is bad, but it's unclear what would break if it got removed.
            width: 92px;
        }
    }

    // Ensure readonly version consumes the same height.
    &.date-time-text-input--readonly {
        @include textStyle($weight: 'Normal', $size: 14px);
        min-height: $single-line-input-height;
        display: flex;
        align-items: center;
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__date-range-picker.scss';
}
