@include theme('radio-group-list') {
    &.radio-group--block {
        // Make radio button behave more like a grid.
        .radio-control__label {
            border: none;
        }
    }

    .horizontal-list {
        height: 100%;
    }

    .horizontal-list__item {
        outline: 1px solid grayscaleColor(3);
        height: 100%;
        margin-right: -1px;
        // Ensure that active/focused items within the list don't get
        // displayed above containing borders.
        z-index: $zindex-default !important;

        &:last-of-type {
            margin-right: 0;
        }
    }

    .radio-control {
        height: 100%;
        min-height: 58px;

        // Make glyphs a slightly lighter gray.
        &:hover .radio-group__glyph--not-selected {
            color: grayscaleColor(5);
        }

        .radio-group__glyph--not-selected {
            color: grayscaleColor(4);
        }
    }
}
