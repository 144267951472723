@include theme('adgroup-create-channel') {
    .radio-control {
        width: 140px;
        height: 110px;
		
        .radio-group__glyph {
            height: 44px;
            width: 88px;
            font-size: 44px;
        }

        .radio-control__label {
            width: 140px;
            height: 110px;
        }
        
        .glyph-channels-other {
            width: 88px;
        }
    }
}
