@include theme('add-targeting-tile') {
    &.button.button--flat.button--type-secondary {
        --mdc-theme-primary: #{grayscaleColor(7)};
        border: 2px dashed grayscaleColor(4);

        &:hover,
        &:focus {
            .button__background {
                background-color: grayscaleColor(1);
            }
        }
    }

    .button__content {
        // Ensure button's contents consumes all available space.
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
