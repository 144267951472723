@mixin groupCardNameInput() {
    width: size-from-base(18);
    min-height: 15px;
    border-radius: $size-xs;

    .data-group-card__input,
    .data-group-card__input:hover,
    .data-group-card__input:focus,
    .data-group-card__input::placeholder,
    .text-control--readonly {
        border-bottom-width: 0;
        height: 15px;
        text-align: center;
        padding: 0 $size-xs;
        margin: 4px 0;
        font-size: 12px;
    }
}

@include theme('data-group-card') {
    @for $i from 1 through 28 {
        &.color-#{$i} {
            @include groupCardNameInput();
            background-color: getColorByIndex($i);

            &:hover {
                @include groupCardNameInput();
            }

            .data-group-card__input,
            .data-group-card__input::placeholder {
                color: getTextColorByBackgroundIndex($i);
            }

            .text-control--readonly {
                display: inline-block;
                line-height: 15px;
                width: 100%;
                color: grayscaleColor(3);
            }
        }
    }
}
