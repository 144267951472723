@include theme('gray-card') {
    border: none;
    box-shadow: none;

    // Ensure that only themed card inherits properties.
    /* stylelint-disable-next-line selector-combinator-whitelist */
    > .card__inner {
        background: grayscaleColor(2);
        height: 100%;
    }
}
