$font-url: '/fonts';
@import 'component-core.scss';

.enhanced-dataview-wrapper {
    padding-top: 40px;
    position: relative;
    align-items: center;

    .enhanced-dataview-filters {
        position: absolute;
        top: 0;
        left: 0;

        .enhanced-dataview-filters__filter {
            padding: 0 8px;
        }

        // Just hide the filters so the form values don't get wiped out.
        &.enhanced-dataview-filters--hidden {
            opacity: 0;
            pointer-events: none;
            height: 0;
        }

        // To account for the size of the filters
        &.enhanced-dataview-filters--with-overflow {
            top: -$size-xs;
        }
    }
}

