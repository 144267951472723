$font-url: '/fonts';
@import 'component-core.scss';


.card {
    $actionBadgeSize: $size-s;
    @include shadow(1);
    position: relative;

    &:hover {
        .card__actions {
            visibility: visible;
            opacity: 1;
        }
    }

    &.card--clickable:not(:disabled) {
        @include hoverShadow(2);
        cursor: pointer;
    }

    .card__inner {
        min-height: 140px;
        padding: $card-padding;
        background-color: $color-card-background;
    }

    .card__title {
        @include cardTitleText();
        margin-bottom: 12px;
    }

    .card__actions {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
        position: absolute;
        right: -($actionBadgeSize / 2);
        top: $size-xxs;
        z-index: $zindex-promoted;
    }

    .card__action-list {
        display: flex;
        flex-direction: column;
    }

    .card__action {
        @include glyphStyleWithBadge(
            $glyphSize: 14px,
            $badgeDiameter: $actionBadgeSize,
            $glyphColor: grayscaleColor(1),
            $badgeColor: grayscaleColor(7)
        );
        @include shadow(2);
        margin-bottom: $size-xxs;
        // Ensure wrapper elements' height match glyph heights.
        line-height: 1;

        &:hover,
        &:focus {
            opacity: 0.8;
        }
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/flat-card';
    @import 'themes/page-card';
    @import 'themes/theme__data-collection-tile.scss';
    @import 'themes/theme__flat-card.scss';
    @import 'themes/theme__flat-page-card.scss';
    @import 'themes/theme__fill-space.scss';
    @import 'themes/theme__gray-card.scss';
    @import 'themes/theme__insights-tile.scss';
    @import 'themes/theme__no-content-padding.scss';
    @import 'themes/theme__page-card.scss';
    @import 'themes/theme__private-contract-marketplace-tile.scss';
    @import 'themes/theme__simple-tile.scss';
    @import 'themes/theme__targeting-tile-with-details.scss';
    @import 'themes/theme__insight-card.scss';
    @import 'themes/theme__flat-insights.scss';
    @import 'themes/theme__flat-insights__no-content-padding.scss';
}
