@include theme('audience-data-group-type') {
    .radio-control {
        width: 140px;
        height: 110px;
		
        .radio-group__glyph {
            font-size: 44px;
        }

        .radio-control__label {
            width: 140px;
            height: 110px;
        }
    }
}
