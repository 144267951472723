@include theme('new-bidlist-4-buttons') {
    width: 832px;
}

@include theme('new-bidlist-3-buttons') {
    width: 636px;
}

@include theme('new-bidlist-2-buttons') {
    width: 440px;
}
