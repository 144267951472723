
@include theme('site-toolbar-user-menu') {
    .drop-down-menu__label {
        @include siteToolbarText();
        opacity: 0.8;
        transition: opacity 0.05s linear;
        padding-right: 0;

        &:hover,
        &:focus {
            opacity: 1;
        }
    }

    .drop-down-menu__menu {
        top: 33px;
    }

    .drop-down-menu__label-glyph {
        $icon-size: $size-s;
        vertical-align: middle;
        display: inline-block;
        width: $icon-size;
        height: $icon-size;
        border-radius: 50%;
        background: $color-site-toolbar-text;
        text-align: center;
        overflow: hidden;

        .glyph {
            @include glyphStyle($size-s, grayscaleColor(5));
        }
    }
}
