@include theme('date-range-picker') {
    // For the standard layout, we'd like to tweak the styling slightly.
    &.date-time-text-input--standard {
        &.date-range-picker__text-input--active input:hover,
        &.date-range-picker__text-input--active input:focus,
        &.date-range-picker__text-input--focused input {
            // Note: We're intentionally not changing the font family here,
            // as that causes the text to get larger. We're just using this
            // to show the "highlighted" effect.
            font-weight: 700;
            outline: none;
            box-shadow: none;
        }

        input {
            text-align: center;
            // Hide elements of input element.
            background: transparent;
            border: none;
        }
    }

    // For the inline layout, we largely rely on the built-in styles, but we want
    // the input's content to be center aligned.
    &.date-time-text-input--inline {
        input {
            text-align: center;
        }
    }

    &.date-time-text-input--readonly {
        color: $color-input-disabled-text;
        margin: 0 $size-xxs;
    }
}
