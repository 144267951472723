@include theme('blue-button') {
    color: primaryShade(1, 1);

    &:hover,
    &:focus {
        color: primaryShade(1, 2);
    }

    &.disabled {
        color: grayscaleColor(5);
    }
}
