// we need the button to not add any sort of margin or padding
@include theme('display-as-link') {
    @include resetButtonStyles();

    &.button--standard,
    &.button--flat {
        @include textStyle($color: var(--color-link), $size: 14px, $weight: 'Normal', $isUppercase: false);
        height: auto;
        border: none;
        display: inline-block;

        --disabled-bg: transparent;
        --box-shadow: 0;
        --box-shadow-hover: 0;
        --box-shadow-focus: 0;

        .button__background {
            background-color: transparent;
        }

        &.button--disabled {
            color: var(--disabled-text);
        }
    }

    .button__content {
        display: unset;
        align-items: unset;
        justify-content: unset;
    }   
}
