@function transparentBlack($opacity) {
    @if $opacity < 0 or $opacity > 1 {
        @error "The opacity for a transparent black must be between 0 and 1.";
    }

    @return rgba(0, 0, 0, $opacity);
}

$shadowDepths: (
    0 2px 6px 1px transparentBlack(0.16),
    0 6px 15px 1px transparentBlack(0.16),
    0 8px 18px 1px transparentBlack(0.16),
    0 12px 24px 1px transparentBlack(0.16),
    0 16px 30px 1px transparentBlack(0.16),
    0 24px 36px 1px transparentBlack(0.24)
);

$reverseShadowDepths: (
    0 -2px 6px 1px transparentBlack(0.16),
    0 -6px 15px 1px transparentBlack(0.16),
    0 -8px 18px 1px transparentBlack(0.16),
    0 -12px 24px 1px transparentBlack(0.16),
    0 -16px 30px 1px transparentBlack(0.16),
    0 -24px 36px 1px transparentBlack(0.24)
);

// Displays a shadow around an element at the provided shadowDepth.  Depth 0 means no shadow.
@mixin shadow($shadowDepth: 1) {
    @if $shadowDepth > length($shadowDepths) or ($shadowDepth * -1 > length($reverseShadowDepths)) {
        @error "Invalid shadow depth: #{$shadowDepth} (should be between 0 and #{length($shadowDepths)})";
    }

    @if $shadowDepth == 0 {
        box-shadow: none;
    } @else if $shadowDepth > 0 {
        box-shadow: nth($shadowDepths, $shadowDepth);
    } @else {
        box-shadow: nth($reverseShadowDepths, $shadowDepth * -1);
    }
}

// Adds a behavior to an element so that, when hovered, a shadow of the
// provided depth is displayed, animated to take transitionTime.
// NOTE: This does not hide any shadow on the original element (and therefore
// only looks effective when the hover shadow is deeper than the shadow on the
// normal element.
@mixin hoverShadow(
    $hoverShadowDepth: 2,
    $transitionTime: 0.1s) {

    // The hover shadow is implemented by creating an initially hidden
    // absolutely positioned element with the shadow on it, then
    // animating the opacity when hovered.  This technique is based on
    // the discussion here: http://tobiasahlin.com/blog/how-to-animate-box-shadow/
    // where the author proposes that the most performant animations are
    // on opacity and transform properties.
    position: relative;

    &:after {
        @include shadow($hoverShadowDepth);
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity $transitionTime ease-in-out;
    }

    &:focus:after,
    &:hover:after {
        opacity: 1;
        pointer-events: none; // this will allow internal content of the target to be clickable
    }
}
