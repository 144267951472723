$font-url: '/fonts' !default;
$glyphDir: '#{$font-url}/glyphs';
@font-face {
	font-family: "glyph-font-bigbuttons";
    	src: url("#{$glyphDir}glyph-font-bigbuttons.eot?f60cb136-72fc-4da6-bb48-ba76a6382bda?#iefix") format("embedded-opentype"),
    		 url("#{$glyphDir}/glyph-font-bigbuttons.woff?f60cb136-72fc-4da6-bb48-ba76a6382bda") format("woff"),
    		 url("#{$glyphDir}/glyph-font-bigbuttons.ttf?f60cb136-72fc-4da6-bb48-ba76a6382bda") format("truetype");
}

.glyph-family-bigbuttons {
	line-height: inherit;
}

.glyph-family-bigbuttons:before {
	font-family: glyph-font-bigbuttons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}
.glyph-family-bigbuttons:after {
	font-family: glyph-font-bigbuttons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}


.glyph-bigbuttons-add-block:before {
    content: "\ea01";
}
.glyph-bigbuttons-add-block-after:after {
    content: "\ea01";
}

.glyph-bigbuttons-relative-adjustment:before {
    content: "\ea02";
}
.glyph-bigbuttons-relative-adjustment-after:after {
    content: "\ea02";
}

.glyph-bigbuttons-remove-block:before {
    content: "\ea03";
}
.glyph-bigbuttons-remove-block-after:after {
    content: "\ea03";
}

.glyph-bigbuttons-replace:before {
    content: "\ea04";
}
.glyph-bigbuttons-replace-after:after {
    content: "\ea04";
}

.glyph-bigbuttons-target:before {
    content: "\ea05";
}
.glyph-bigbuttons-target-after:after {
    content: "\ea05";
}

