$font-url: '/fonts';
@import 'component-core.scss';

.dialog-core .panel {
    // Note: keep this value in sync with `$headerHeight` in `DialogHeader.scss`.
    $header-height: $size-xxl;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    transition: right 0.3s ease-in-out;
    width: 455px;

    &.dialog-core__dialog--transitioning {
        right: -500px;
    }

    .panel__content {
        padding: 0 20px 30px 20px;
        height: 100%;
        max-height: calc(100% - #{$header-height});
        overflow-y: auto;
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__api-sample.scss';
    @import 'themes/theme__comscore-report.scss';
    @import 'themes/theme__data-rates.scss';
    @import 'themes/theme__dynamic-rules.scss';
    @import 'themes/theme__nielsen-report.scss';
    @import 'themes/theme__private-contract-forecast.scss';
    @import 'themes/theme__troubleshooting.scss';
    @import 'themes/theme__deal-details.scss';
    @import 'themes/theme__miaozhen-report.scss';
}
