$font-url: '/fonts';
@import 'component-core.scss';

.validation-error-inline {
    display: inline-block;

    .validation-error-inline__glyph {
        background-color: $color-validation-error-text;
        display: inline-block;
        border-radius: 50%;
        color: grayscaleColor(1);
        // Ensure validation errors are displayed above neighboring form controls.
        z-index: $zindex-active + 1;
        font-size: 14px;
        width: 20px;
        height: 20px;
        line-height: 20px;
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__proposal-deal-details.scss';
}
