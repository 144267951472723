$font-url: '/fonts';
@import 'component-core.scss';

.stepped-progress-bar {
    $grayscaleColor: 4;
    $pendingColor: grayscaleColor($grayscaleColor);
    $disabledColor: grayscaleColor($grayscaleColor);
    $disabledTextColor: grayscaleColor(1);
    $glyphBorderWidth: 2px;
    $glyphCircleSize: 30px;

    display: flex;
    padding: 0 100px;

    &.stepped-progress-bar--vertical {
        display: inline-flex;
        flex-direction: column;
        padding: 0;
        min-height: 100%;
    }

    // Wrapper around each child step prop.
    .stepped-progress-bar__step-wrapper {
        flex-grow: 1;

        &.stepped-progress-bar__step-wrapper--vertical {
            display: flex;
            flex-direction: column;
            min-height: 90px;
        }
    }

    // Child Step Component 0-----
    .stepped-progress-bar-step {
        display: flex;
        align-items: center;

        .stepped-progress-bar-step__circle {
            // Adding a border for all circles for consistency
            border-width: $glyphBorderWidth;
            border-style: solid;
        }

        // if this step is pending, we represent the state as a grey circle with a white center.
        &.stepped-progress-bar-step--pending {
            .stepped-progress-bar-step__circle {
                border-color: $pendingColor;
            }
        }

        // when a step is active we show a solid circle (blue by default) with the loading dots.
        &.stepped-progress-bar-step--active {
            .stepped-progress-bar-step__circle {
                --step-active-color: unset;
                border-color: var(--step-active-color);
                background-color: var(--step-active-color);
            }

            /* stylelint-disable-next-line order/order */
            @each $i in $primary-palette-numbers {
                &.stepped-progress-bar-step--active-#{$i} {
                    --step-active-color: #{primaryColor($i)};
                }
            }
        }

        // show the active bar with a blue (by default) background.
        &.stepped-progress-bar-step--active:not(.stepped-progress-bar-step--complete):not(.stepped-progress-bar-step--disabled) {
            .stepped-progress-bar-step__progress {
                --step-progress-color: unset;
                transition: width 250ms ease-out;
                border-color: var(--step-active-color);
                background-color: var(--step-active-color);
            }

            @each $i in $primary-palette-numbers {
                &.stepped-progress-bar-step--active-#{$i} {
                    --step-progress-color: #{primaryColor($i)};
                }
            }
        }

        // for steps that are complete, we show the progress bar and the circle as completely green.
        // for the step previous to this we have a gradient from blue to green (default).
        &.stepped-progress-bar-step--complete {
            .stepped-progress-bar-step__progress-wrapper,
            .stepped-progress-bar-step__circle {
                --step-completed-color: unset;
                --step-completed-text-color: unset;
                color: var(--step-completed-text-color);
                border-color: var(--step-completed-color);
                background-color: var(--step-completed-color);
            }

            // The wrapper is the visible green bar on each completed step so we bump up from 2 to 4px.
            &:not(.stepped-progress-bar-step--with-gradient) {
                .stepped-progress-bar-step__progress-wrapper {
                    height: 4px;
                }
            }

            @each $i in $primary-palette-numbers {
                &.stepped-progress-bar-step--completed-#{$i} {
                    --step-completed-color: #{primaryColor($i)};
                    --step-completed-text-color: #{textColorForPrimaryColor(
                        $i
                    )};
                }
            }
        }

        &.stepped-progress-bar-step--disabled {
            .stepped-progress-bar-step__index {
                color: $disabledColor;
            }

            .stepped-progress-bar-step__circle {
                color: $disabledTextColor;
                border-color: $disabledColor;
                background-color: $disabledColor;
            }
        }

        // Absolutely positioned box under each circle for containing the Step #, The Step Summary, and the Step Details
        .stepped-progress-bar-step__summary-wrapper {
            position: absolute;
            left: -(100px - (($glyphCircleSize + $glyphBorderWidth) / 2));
            top: $glyphCircleSize + (2 * $glyphBorderWidth);
            display: flex;
            flex-direction: column;
            align-items: center;
            color: textColorForGrayscaleColor(1);
            line-height: 20px;
        }

        .stepped-progress-bar-step__summary__details {
            width: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        // Step Number "Step 1"
        .stepped-progress-bar-step__index {
            @include textStyle(
                $size: 18px,
                $weight: 'Heavy',
                $color: grayscaleColor(7)
            );
            margin: 18px 0 8px;
        }

        // Step Details which using the step template
        .stepped-progress-bar-step__summary {
            color: grayscaleColor(6);
        }

        // Step Details "1 of 2 items completed"
        .stepped-progress-bar-step__summary-details {
            @include textStyle(
                $color: grayscaleColor(5),
                $size: 14px,
                $isOblique: true,
                $weight: 'Medium'
            );
            margin-top: 8px;
        }

        // Animated dot loading indicator
        .stepped-progress-bar-step--loading-dots {
            height: 20px;
            width: 20px;
            margin: 3px 0 0 3px;
            display: flex;
        }

        // Static loading icon
        .stepped-progress-bar-step--static-loading-glyph {
            color: grayscaleColor(1);
            font-size: 16px;
            margin-top: 5px;
        }

        // Loading bar (blue by default) of active step
        .stepped-progress-bar-step__progress {
            flex-grow: 1;
            height: 4px;
            padding: 0 1px;
            margin-top: -1px;
        }

        // main circle of each step
        .stepped-progress-bar-step__circle {
            transition: background-color 250ms ease-in;
            height: $glyphCircleSize;
            width: $glyphCircleSize;
            border-radius: 50%;
            position: relative;
            max-width: $glyphCircleSize;
        }

        // wrapper around the progress bar.  This is used to show the gray line between steps.
        .stepped-progress-bar-step__progress-wrapper {
            display: flex;
            background-color: $pendingColor;
            flex-grow: 1;
            height: 2px;
        }

        // Glyph for the completed steps.
        .stepped-progress-bar-step__glyph-complete {
            padding-top: $size-xxs;
        }

        .stepped-progress-bar-step__glyph-disabled {
            padding: 6px;
            transform: rotate(90deg);
        }
    }

    .stepped-progress-bar-step--vertical {
        flex-direction: column;
        flex: 1;

        // show the active bar with a blue (by default) background.
        &.stepped-progress-bar-step--active:not(.stepped-progress-bar-step--complete) {
            .stepped-progress-bar-step__progress {
                transition: height 250ms ease-out;
            }
        }

        // for steps that are complete, we show the progress bar and the circle as completely green.
        // for the step previous to this we have a gradient from blue to green (default).
        &.stepped-progress-bar-step--complete {
            // The wrapper is the visible green bar on each completed step so we bump up from 2 to 4px.
            &:not(.stepped-progress-bar-step--with-gradient) {
                .stepped-progress-bar-step__progress-wrapper {
                    width: 4px;
                    height: 100%;
                }
            }
        }

        &.stepped-progress-bar-step--complete-step {
            flex: 0;
        }

        // Absolutely positioned box under each circle for containing the Step #, The Step Summary, and the Step Details
        .stepped-progress-bar-step__summary-wrapper {
            flex-direction: row;
            line-height: 20px;
            top: ($glyphCircleSize - 20) / 2 - 2; // accounting for line-height
            left: $glyphCircleSize + (2 * $glyphBorderWidth) + 8; // 8 for some margin on the left
        }

        .stepped-progress-bar-step__summary__details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        // Step Number "Step 1"
        .stepped-progress-bar-step__index {
            margin: 0 0 4px;
        }

        // Loading bar (blue by default) of active step
        .stepped-progress-bar-step__progress {
            flex-grow: 1;
            width: 4px;
            padding: 1px 0;
            margin-top: 0;
            margin-left: -1px;
        }

        // wrapper around the progress bar.  This is used to show the gray line between steps.
        .stepped-progress-bar-step__progress-wrapper {
            flex-direction: column;
            width: 2px;
            height: 100%;
        }
    }

    @import 'themes/theme__cn-adv-ent.scss';
    @import 'themes/theme__proposal-activation.scss';
}
