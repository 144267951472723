@include theme('small-floating-glyph-only') {
    .radio-control {
        width: $size-l;
        height: $size-m;
        min-width: $size-l;
        padding: 0;
        border: none;

        .radio-group__glyph,
        .glyph__inner {
            font-size: inherit;
        }

        .radio-group__glyph-label {
            display: none;
        }

        .radio-control__label {
            border: none;
            background: transparent;
            padding: 0;
        }
    }

    .horizontal-list .radio-control--enabled .radio-control__label--selected {
        background: transparent;
    }
}
