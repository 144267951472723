$font-url: '/fonts';
@import 'component-core.scss';


.dialog-core {
    .dialog-core__dialog {
        @include shadow(6);
        position: fixed;
        background-color: $color-dialog-content-background;
    }

    .dialog-core__overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: transparent;

        &.dialog-core__overlay--dark,
        &.dialog-core__overlay--light {
            background-color: grayscaleColor(7);
            transition: opacity 0.25s ease-in-out;
        }

        &.dialog-core__overlay--dark {
            opacity: 0.45;
        }

        &.dialog-core__overlay--light {
            opacity: 0.2;
        }

        &.dialog-core__overlay--transitioning {
            opacity: 0;
            pointer-events: none;
        }
    }

    // Ensure that dragging dialogs doesn't select title's text.
    .react-draggable .dialog-header__title {
        user-select: none;
    }
}
