@mixin fillSpace() {
    // Ensure that only themed card inherits styling
    /* stylelint-disable-next-line selector-combinator-whitelist */
    > .card__inner {
        // Ensure the card consumes all available space.
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 0;
    }
}
