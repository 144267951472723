@include theme('data-group-browse') {
    width: 50%;
    border-width: 1px;
    border-color: grayscaleColor(5);
    height: $size-m;
    margin-bottom: $size-xxs;

    .fixed-content-control__control {
        padding: size-from-base(0.5);
    }
}
