$font-url: '/fonts';
@import 'component-core.scss';

.step-status {
    $size-step-circle: 21px;
    $size-step-text: 12px;
    $size-step-glyph: 10px;
    position: relative;

    &__steps {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .step-status__step {
            &.step-status__step--current {
                .step-status__step--complete {
                    @include primaryColorBackgroundWithText(1);
                }

                .step-status__step--incomplete {
                    @include primaryColorBackgroundWithText(1);
                    border-color: primaryColor(1);
                }
            }

            .step-status__step--complete {
                @include glyphStyleWithBadge(
                    $size-step-glyph,
                    $size-step-circle,
                    grayscaleColor(1),
                    stoplightColor('green')
                );
                cursor: pointer;
            }

            .step-status__step--incomplete {
                border: 1px solid grayscaleColor(4);
                background-color: grayscaleColor(1);
                border-radius: 50%;
                width: $size-step-circle;
                height: $size-step-circle;
                color: grayscaleColor(4);
                font-size: $size-step-text;
                display: flex;
                align-items: center;
                justify-content: center;
                // We don't want to the "text selection" cursor to appear.
                cursor: default;
            }
        }

        .step-status__line {
            flex-grow: 1;
            height: 1px;
            border-top: 1px solid grayscaleColor(4);

            &.step-status__line--complete {
                border-color: stoplightColor('green');
            }
        }
    }
}
