$font-url: '/fonts';
@import 'component-core.scss';

///////////////////////////////////////////////////////////////////////////////
// This file should ONLY contain rules about table layout that are
// common to all table stylings. If you want to make changes to a
// specific style (or to create a new style) add it to one of the
// SCSS files in the "theme" directory.
///////////////////////////////////////////////////////////////////////////////
.datatable {
    overflow: hidden;

    .datatable__loading {
        padding: $size-s 0;
        text-align: center;
    }

    .datatable__loading-more {
        opacity: 0.5;
    }

    .datatable__row {
        display: flex;
        flex-direction: row;

        &.datatable__row--offscreen {
            overflow: none;

            .datatable__row--offscreen__content {
                background-color: grayscaleColor(2);
                margin: 4px;
                width: 100%;
            }
        }

        // Add this class to inputs in a table where the inline validation tooltip is required.
        // There is a helper function in utils/ValidationService.js to add this class.
        .input-inline-validation {
            display: flex;
            align-items: center;

            // We can have myriad data rendered inline here and need to be specific about which
            // tooltip we're targeting.
            /* stylelint-disable-next-line selector-combinator-whitelist */
            > .tooltip__anchor {
                margin-left: $size-xxs;
            }
        }

        .datatable__column {
            position: relative;
            height: 100%;
            overflow: hidden;
            display: flex;

            .datatable__column-content {
                max-width: 100%;

                &.datatable__column--not-fixed {
                    z-index: $zindex-promoted;
                }

                &.datatable__column--fixed {
                    // Ensure fixed columns appear above others.
                    z-index: $zindex-promoted + 1;
                }

                &.datatable__column-content-vert-align-center {
                    align-self: center;
                }

                &.datatable__column-content-vert-align-top {
                    align-self: flex-start;
                }

                &.datatable__column-content-vert-align-bottom {
                    align-self: flex-end;
                }
            }

            .datatable__column--first {
                content: '';
            }
        }
    }

    // Rules specific to the table header (as opposed to the body).
    .datatable__header {
        .datatable__row {
            text-wrap: none;
            text-overflow: ellipsis;

            .datatable__column {
                display: flex;
                cursor: default;

                &.datatable__column--sortable {
                    cursor: pointer;
                    display: flex;

                    .datatable__sort-arrow {
                        margin: auto $size-xxs auto 0;
                    }
                }

                .datatable__column--header-title {
                    user-select: none;
                    display: flex;

                    &-align-right {
                        margin-left: auto;
                    }

                    &-align-center {
                        margin-left: auto;
                        margin-right: auto;
                    }

                    span {
                        width: 100%;
                    }
                }
            }
        }
    }

    // Rules specific to the table footer and additional header (as opposed to the body).
    .datatable__additional-header.datatable__row,
    .datatable__footer.datatable__row {
        text-wrap: none;
        text-overflow: ellipsis;

        // Ensure links are styled correctly.
        a.datatable__column { /* stylelint-disable-line selector-no-qualifying-type */
            cursor: default;
        }
    }

    // Rules specific to the table body (as opposed to the header).
    .datatable__body {
        overflow-y: auto;
        overflow-x: hidden;

        .datatable__loadmore-container {
            display: flex;
            flex-direction: row;
            padding: 8px;
        }

        a {
            text-decoration: none;
        }
    }

    .datatable__scrollbar__container {
        width: 100%;

        .datatable__scrollbar {
            display: block;
            overflow-y: hidden;
            overflow-x: scroll;
            width: auto;

            .datatable__scrollbar-content {
                display: inline-block;
                height: 0;
            }
        }
    }
}
