@include theme('export-code') {
    .task-dialog__content {
        overflow-y: auto;

        // Does a similar (and redundant) thing as flex-grow: 1, but allows children to continue to use height: 100% if needed
        height: 100%;
    }

    .dialog-status-page__message {
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;

        .button {
            flex-direction: column;
        }
    }
}
