$font-url: '/fonts' !default;
$glyphDir: '#{$font-url}/glyphs';
@font-face {
	font-family: "glyph-font-channels";
    	src: url("#{$glyphDir}glyph-font-channels.eot?f60cb136-72fc-4da6-bb48-ba76a6382bda?#iefix") format("embedded-opentype"),
    		 url("#{$glyphDir}/glyph-font-channels.woff?f60cb136-72fc-4da6-bb48-ba76a6382bda") format("woff"),
    		 url("#{$glyphDir}/glyph-font-channels.ttf?f60cb136-72fc-4da6-bb48-ba76a6382bda") format("truetype");
}

.glyph-family-channels {
	line-height: inherit;
}

.glyph-family-channels:before {
	font-family: glyph-font-channels !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}
.glyph-family-channels:after {
	font-family: glyph-font-channels !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}


.glyph-channels-audio:before {
    content: "\ea01";
}
.glyph-channels-audio-after:after {
    content: "\ea01";
}

.glyph-channels-ctv:before {
    content: "\ea02";
}
.glyph-channels-ctv-after:after {
    content: "\ea02";
}

.glyph-channels-display:before {
    content: "\ea03";
}
.glyph-channels-display-after:after {
    content: "\ea03";
}

.glyph-channels-mobile:before {
    content: "\ea04";
}
.glyph-channels-mobile-after:after {
    content: "\ea04";
}

.glyph-channels-native:before {
    content: "\ea05";
}
.glyph-channels-native-after:after {
    content: "\ea05";
}

.glyph-channels-other:before {
    content: "\ea06";
}
.glyph-channels-other-after:after {
    content: "\ea06";
}

.glyph-channels-out-of-home:before {
    content: "\ea07";
}
.glyph-channels-out-of-home-after:after {
    content: "\ea07";
}

.glyph-channels-video:before {
    content: "\ea08";
}
.glyph-channels-video-after:after {
    content: "\ea08";
}

.glyph-channels-video-player-size:before {
    content: "\ea09";
}
.glyph-channels-video-player-size-after:after {
    content: "\ea09";
}

