$font-url: '/fonts';
@import 'component-core.scss';

.dialog-action-bar {
    border-top: 1px solid grayscaleColor(4);
    height: $size-xxl;
    min-height: $size-xxl;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .dialog-action-bar__button,
    .dialog-action-bar__wrapped__button {
        margin-right: $size-xs;
    }

    // If this is the only button in the action bar, make it fill the space available.
    // This will mostly be used in alert-type message dialogs and very simple task dialogs
    // (since most other dialogs should have a cancel button in addition to some other button).
    .dialog-action-bar__button:only-child {
        margin-right: 0;
        width: 100%;
        height: 100%;
        font-size: 13px;
        border-radius: 0;

        .button__background {
            border-radius: 0;
        }
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__first-run-wizard.scss';
    @import 'themes/theme__private-contract-details-dialog.scss';
}
