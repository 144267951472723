﻿//=============================================================================================
// Non-Whitelabelable colors
// These colors cannot be overridden by whitelabels.  They are intended to be used as is.

// 7 Shades of Gray, mainly used for text and backgrounds.
/* stylelint-disable color-no-hex */
$grayscale-palette: (
    #ffffff /* 1 - Clean Sock (white) */,
    #f2f2f3 /* 2 - Dirty Sock */,
    #e6e7e7 /* 3 - Dove */,
    #cacccb /* 4 - Pigeon */,
    #9a9a9a /* 5 - Elephant */,
    #666767 /* 6 - Storm */,
    #323333 /* 7 - Essentially Black */
);
/* stylelint-enable */

@function grayscaleColor($grayscaleColorNumber) {
    @if $grayscaleColorNumber <
        1 or
        $grayscaleColorNumber >
        length($grayscale-palette) {
        @error "Invalid grayscale color: #{$grayscaleColorNumber} (should be between 1 and #{length($grayscale-palette)}).";
    }

    @return nth($grayscale-palette, $grayscaleColorNumber);
}

@mixin grayscaleBackgroundWithText($grayscaleColorNumber) {
    background-color: grayscaleColor($grayscaleColorNumber);
    color: textColorForGrayscaleColor($grayscaleColorNumber);
}

// Neutrals.
/* stylelint-disable color-no-hex */
$neutral-palette: (
    --neutral1,
    --neutral2,
    --neutral3,
    --neutral4,
    --neutral5,
    --neutral6,
    --neutral7,
    --neutral8,
    --neutral9
);
/* stylelint-enable */

@function neutralColor($neutralColorNumber) {
    @if $neutralColorNumber <
        1 or
        $neutralColorNumber >
        length($neutral-palette) {
        @error "Invalid neutral color: #{$neutralColorNumber} (should be between 1 and #{length($neutral-palette)}).";
    }

    @return var(nth($neutral-palette, $neutralColorNumber));
}

@mixin neutralBackgroundWithText($neutralColorNumber) {
    background-color: neutralColor($neutralColorNumber);
    color: textColorForNeutralColor($neutralColorNumber);
}

// Tints and Shades of the Primary Palette

// These (not commonly used) colors are the darkening (shading) and lightening
// (tinting) of the primary colors, and are procedurally generated from the primary
// palette (even if the primary palette is whitelabeled).
//
// They *can* be overridden individually by whitelabels, but, if they are overridden,
// it is best that they are minor edits of the procedurally generated colors from the
// mixing functions (to avoid weird behavior where seemingly random colors are used,
// where they were intended to be darker or lighter versions of another color).
$primary-shades: (20%, 40%, 60%);
$primary-tints: (20%, 40%, 60%, 80%, 90%);

// Stoplight colors, mainly used for status or indications of positive
// or negative changes.
/* stylelint-disable color-no-hex */
$stoplight-palette: (
    'light-green': #eaf5ea,
    'green': #319e30,
    'dark-green': #1d5f1d,
    'light-yellow': #fefaea,
    'yellow': #f7d031,
    'dark-yellow': #947d1d,
    'light-red': #fde9e9,
    'red': #f0503f,
    'dark-red': #8a1516
);
/* stylelint-enable */

$stoplight-palette-names: map-keys($stoplight-palette);

// $stoplight should be one of the $stoplight-palette keys.
@function stoplightColor($stoplight) {
    @if (not map-has-key($stoplight-palette, $stoplight)) {
        @error "Invalid stoplight color: #{$stoplight} (should be one of: #{$stoplight-palette-names}).";
    }

    @return map-get($stoplight-palette, $stoplight);
}

@mixin stoplightBackgroundWithText($stoplight) {
    @if (str-index($stoplight, 'light-')) {
        color: grayscaleColor(7);
    } @else {
        color: grayscaleColor(1);
    }
    background-color: stoplightColor($stoplight);
}

//=============================================================================================
// Whitelabelable colors
// These colors can be overridden by whitelabels. In particular, the whitelabeler can define
// various variables to override the primary colors, their shades, and their coordinating
// text colors in their whitelabeled SCSS file (e.g., cadreon.scss).

// Primary Palette

// These colors are the main accent colors for the application.
/* stylelint-disable color-no-hex */
$primary-palette: (
    /* 1 - TTD Blue */ 1: --primary1,
    /* 2 - Marine */ 2: --primary2,
    /* 3 - Deep Reef */ 3: --primary3,
    /* 4 - Amethyst */ 4: --primary4,
    /* 5 - Sunshine */ 5: --primary5,
    /* 6 - Sea Foam */ 6: --primary6,
    /* 7 - Fuchsia */ 7: --primary7,
    /* 8 - Sunset */ 8: --primary8
);
/* stylelint-enable */

$primary-palette-numbers: map-keys($primary-palette);

@function isValidPrimaryColor($primaryColorNumber) {
    @if map-get($primary-palette, $primaryColorNumber) == null {
        @return false;
    }

    @return true;
}

@function getInvalidPrimaryColorError($primaryColorNumber) {
    @return 'Invalid primary color: #{$primaryColorNumber} (should be one of #{$primary-palette-numbers}).';
}

@function primaryColor($primaryColorNumber) {
    @if isValidPrimaryColor($primaryColorNumber) == false {
        @error getInvalidPrimaryColorError($primaryColorNumber);
    }

    @return var(map-get($primary-palette, $primaryColorNumber));
}

@function primaryShade($primaryColorNumber, $shadeNumber) {
    @if isValidPrimaryColor($primaryColorNumber) == false {
        @error getInvalidPrimaryColorError($primaryColorNumber);
    }
    @if $shadeNumber < 1 or $shadeNumber > length($primary-shades) {
        @error "Invalid shade: #{$shadeNumber} (should be between 1 and #{length($primary-shades)}).";
    }
    @return var(--primary#{$primaryColorNumber}-shade#{$shadeNumber});
}

@function primaryTint($primaryColorNumber, $tintNumber) {
    @if isValidPrimaryColor($primaryColorNumber) == false {
        @error getInvalidPrimaryColorError($primaryColorNumber);
    }
    @if $tintNumber < 1 or $tintNumber > length($primary-tints) {
        @error "Invalid tint: #{$tintNumber} (should be between 1 and #{length($primary-tints)}).";
    }
    @return var(--primary#{$primaryColorNumber}-tint#{$tintNumber});
}

// Coordinating Text Colors for the Primary Palette
// These structures define which foreground text color should be used for a particular primary, shade
// or tint.
// These are the default light and dark text colors.  They can be overridden by defining them and providing
// another color.
$color-text-light: grayscaleColor(1) !default;
$color-text-dark: grayscaleColor(7) !default;

$text-colors: (
    'primary': (
        '1': $color-text-light,
        '2': $color-text-light,
        '3': $color-text-light,
        '4': $color-text-light,
        '5': $color-text-light,
        '6': $color-text-light,
        '7': $color-text-light,
        '8': $color-text-light
    ),
    'shade': (
        '1': (
            '1': $color-text-light,
            '2': $color-text-light,
            '3': $color-text-light
        ),
        '2': (
            '1': $color-text-light,
            '2': $color-text-light,
            '3': $color-text-light
        ),
        '3': (
            '1': $color-text-light,
            '2': $color-text-light,
            '3': $color-text-light
        ),
        '4': (
            '1': $color-text-light,
            '2': $color-text-light,
            '3': $color-text-light
        ),
        '5': (
            '1': $color-text-light,
            '2': $color-text-light,
            '3': $color-text-light
        ),
        '6': (
            '1': $color-text-light,
            '2': $color-text-light,
            '3': $color-text-light
        ),
        '7': (
            '1': $color-text-light,
            '2': $color-text-light,
            '3': $color-text-light
        ),
        '8': (
            '1': $color-text-light,
            '2': $color-text-light,
            '3': $color-text-light
        ),
    ),
    'tint': (
        '1': (
            '1': $color-text-light,
            '2': $color-text-dark,
            '3': $color-text-dark,
            '4': $color-text-dark,
            '5': $color-text-dark
        ),
        '2': (
            '1': $color-text-light,
            '2': $color-text-dark,
            '3': $color-text-dark,
            '4': $color-text-dark,
            '5': $color-text-dark
        ),
        '3': (
            '1': $color-text-dark,
            '2': $color-text-dark,
            '3': $color-text-dark,
            '4': $color-text-dark,
            '5': $color-text-dark
        ),
        '4': (
            '1': $color-text-light,
            '2': $color-text-dark,
            '3': $color-text-dark,
            '4': $color-text-dark,
            '5': $color-text-dark
        ),
        '5': (
            '1': $color-text-dark,
            '2': $color-text-dark,
            '3': $color-text-dark,
            '4': $color-text-dark,
            '5': $color-text-dark
        ),
        '6': (
            '1': $color-text-dark,
            '2': $color-text-dark,
            '3': $color-text-dark,
            '4': $color-text-dark,
            '5': $color-text-dark
        ),
        '7': (
            '1': $color-text-light,
            '2': $color-text-dark,
            '3': $color-text-dark,
            '4': $color-text-dark,
            '5': $color-text-dark
        ),
        '8': (
            '1': $color-text-light,
            '2': $color-text-dark,
            '3': $color-text-dark,
            '4': $color-text-dark,
            '5': $color-text-dark
        ),
    ),
    'grayscale': (
        '1': $color-text-dark,
        '2': $color-text-dark,
        '3': $color-text-dark,
        '4': $color-text-dark,
        '5': $color-text-light,
        '6': $color-text-light,
        '7': $color-text-light
    ),
    'neutral': (
        '1': $color-text-dark,
        '2': $color-text-dark,
        '3': $color-text-dark,
        '4': $color-text-dark,
        '5': $color-text-dark,
        '6': $color-text-dark,
        '7': $color-text-light,
        '8': $color-text-light,
        '9': $color-text-light
    ),
);

// Helpful functions to return the coordinating text color for a given palette color.
@function textColorForPrimaryColor($primaryColorNumber) {
    @if isValidPrimaryColor($primaryColorNumber) == false {
        @error getInvalidPrimaryColorError($primaryColorNumber);
    }

    @return map-get(map-get($text-colors, 'primary'), '#{$primaryColorNumber}');
}

@function textColorForPrimaryShade($primaryColorNumber, $shadeNumber) {
    @if isValidPrimaryColor($primaryColorNumber) == false {
        @error getInvalidPrimaryColorError($primaryColorNumber);
    }
    @if $shadeNumber < 1 or $shadeNumber > length($primary-shades) {
        @error "Invalid shade: #{$shadeNumber} (should be between 1 and #{length($primary-shades)}).";
    }

    @return map-get(
        map-get(map-get($text-colors, 'shade'), '#{$primaryColorNumber}'),
        '#{$shadeNumber}'
    );
}

@function textColorForPrimaryTint($primaryColorNumber, $tintNumber) {
    @if isValidPrimaryColor($primaryColorNumber) == false {
        @error getInvalidPrimaryColorError($primaryColorNumber);
    }
    @if $tintNumber < 1 or $tintNumber > length($primary-tints) {
        @error "Invalid tint: #{$tintNumber} (should be between 1 and #{length($primary-tints)}).";
    }

    @return map-get(
        map-get(map-get($text-colors, 'tint'), '#{$primaryColorNumber}'),
        '#{$tintNumber}'
    );
}

@function textColorForGrayscaleColor($grayscaleColorNumber) {
    @if $grayscaleColorNumber <
        1 or
        $grayscaleColorNumber >
        length($grayscale-palette) {
        @error "Invalid grayscale color: #{$grayscaleColorNumber} (should be between 1 and #{length($grayscale-palette)}).";
    }

    @return map-get(
        map-get($text-colors, 'grayscale'),
        '#{$grayscaleColorNumber}'
    );
}

@function textColorForNeutralColor($neutralColorNumber) {
    @if $neutralColorNumber <
        1 or
        $neutralColorNumber >
        length($neutral-palette) {
        @error "Invalid neutral color: #{$neutralColorNumber} (should be between 1 and #{length($neutral-palette)}).";
    }

    @return map-get(map-get($text-colors, 'neutral'), '#{$neutralColorNumber}');
}

// Helpful mixins to include when you need a palette color and its coordinating text color.
@mixin primaryColorBackgroundWithText($primaryColorNumber) {
    background-color: primaryColor($primaryColorNumber);
    color: textColorForPrimaryColor($primaryColorNumber);
}

@mixin primaryShadeBackgroundWithText($primaryColorNumber, $shadeNumber) {
    background-color: primaryShade($primaryColorNumber, $shadeNumber);
    color: textColorForPrimaryShade($primaryColorNumber, $shadeNumber);
}

@mixin primaryTintBackgroundWithText($primaryColorNumber, $tintNumber) {
    background-color: primaryTint($primaryColorNumber, $tintNumber);
    color: textColorForPrimaryTint($primaryColorNumber, $tintNumber);
}

/* stylelint-disable */
:export {
    grayScaleColor1: grayscaleColor(1);
    grayScaleColor2: grayscaleColor(2);
    grayScaleColor3: grayscaleColor(3);
    grayScaleColor4: grayscaleColor(4);
    grayScaleColor5: grayscaleColor(5);
    grayScaleColor6: grayscaleColor(6);
    grayScaleColor7: grayscaleColor(7);
    neutralColor1: neutralColor(1);
    neutralColor2: neutralColor(2);
    neutralColor3: neutralColor(3);
    neutralColor4: neutralColor(4);
    neutralColor5: neutralColor(5);
    neutralColor6: neutralColor(6);
    neutralColor7: neutralColor(7);
    neutralColor8: neutralColor(8);
    primaryColor1: primaryColor(1);
    primaryColor2: primaryColor(2);
    primaryColor3: primaryColor(3);
    primaryColor4: primaryColor(4);
    primaryColor5: primaryColor(5);
    primaryColor6: primaryColor(6);
    primaryColor7: primaryColor(7);
    primaryColor8: primaryColor(8);
    primary1Shade1: primaryShade(1, 1);
    primary1Shade2: primaryShade(1, 2);
    primary1Shade3: primaryShade(1, 3);
    primary2Shade1: primaryShade(2, 1);
    primary2Shade2: primaryShade(2, 2);
    primary2Shade3: primaryShade(2, 3);
    primary3Shade1: primaryShade(3, 1);
    primary3Shade2: primaryShade(3, 2);
    primary3Shade3: primaryShade(3, 3);
    primary4Shade1: primaryShade(4, 1);
    primary4Shade2: primaryShade(4, 2);
    primary4Shade3: primaryShade(4, 3);
    primary5Shade1: primaryShade(5, 1);
    primary5Shade2: primaryShade(5, 2);
    primary5Shade3: primaryShade(5, 3);
    primary6Shade1: primaryShade(6, 1);
    primary6Shade2: primaryShade(6, 2);
    primary6Shade3: primaryShade(6, 3);
    primary7Shade1: primaryShade(7, 1);
    primary7Shade2: primaryShade(7, 2);
    primary7Shade3: primaryShade(7, 3);
    primary8Shade1: primaryShade(8, 1);
    primary8Shade2: primaryShade(8, 2);
    primary8Shade3: primaryShade(8, 3);
    primary1Tint1: primaryTint(1, 1);
    primary1Tint2: primaryTint(1, 2);
    primary1Tint3: primaryTint(1, 3);
    primary1Tint4: primaryTint(1, 4);
    primary1Tint5: primaryTint(1, 5);
    primary2Tint1: primaryTint(2, 1);
    primary2Tint2: primaryTint(2, 2);
    primary2Tint3: primaryTint(2, 3);
    primary2Tint4: primaryTint(2, 4);
    primary2Tint5: primaryTint(2, 5);
    primary3Tint1: primaryTint(3, 1);
    primary3Tint2: primaryTint(3, 2);
    primary3Tint3: primaryTint(3, 3);
    primary3Tint4: primaryTint(3, 4);
    primary3Tint5: primaryTint(3, 5);
    primary4Tint1: primaryTint(4, 1);
    primary4Tint2: primaryTint(4, 2);
    primary4Tint3: primaryTint(4, 3);
    primary4Tint4: primaryTint(4, 4);
    primary4Tint5: primaryTint(4, 5);
    primary5Tint1: primaryTint(5, 1);
    primary5Tint2: primaryTint(5, 2);
    primary5Tint3: primaryTint(5, 3);
    primary5Tint4: primaryTint(5, 4);
    primary5Tint5: primaryTint(5, 5);
    primary6Tint1: primaryTint(6, 1);
    primary6Tint2: primaryTint(6, 2);
    primary6Tint3: primaryTint(6, 3);
    primary6Tint4: primaryTint(6, 4);
    primary6Tint5: primaryTint(6, 5);
    primary7Tint1: primaryTint(7, 1);
    primary7Tint2: primaryTint(7, 2);
    primary7Tint3: primaryTint(7, 3);
    primary7Tint4: primaryTint(7, 4);
    primary7Tint5: primaryTint(7, 5);
    primary8Tint1: primaryTint(8, 1);
    primary8Tint2: primaryTint(8, 2);
    primary8Tint3: primaryTint(8, 3);
    primary8Tint4: primaryTint(8, 4);
    primary8Tint5: primaryTint(8, 5);
    stoplightLightGreen: stoplightColor('light-green');
    stoplightLightYellow: stoplightColor('light-yellow');
    stoplightLightRed: stoplightColor('light-red');
    stoplightGreen: stoplightColor('green');
    stoplightYellow: stoplightColor('yellow');
    stoplightRed: stoplightColor('red');
    stoplightDarkGreen: stoplightColor('dark-green');
    stoplightDarkYellow: stoplightColor('dark-yellow');
    stoplightDarkRed: stoplightColor('dark-red');
    primaryPaletteNumbers: $primary-palette-numbers;
    stoplightPaletteNames: $stoplight-palette-names;
}
/* stylelint-enable */
