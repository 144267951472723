@include theme('data-view-toolbar') {

    // override the
    margin: 0 0 0 -16px;

    .drop-down-menu__label {
        .glyph__inner {
            transform: rotate(90deg) translateY(-4px);
            color: grayscaleColor(1);
        }
    }
}
