@import '_page-card';
@import '_flat-card';

@include theme('flat-page-card') {
    @include flatCard();
    @include pageCard();

    // Ensure that only themed card inherits padding.
    /* stylelint-disable-next-line selector-combinator-whitelist */
    > .card__inner {
        padding: $size-m;
    }
}
