@include theme('toolbar-action') {
    margin-right: $size-l;

    .drop-down-menu__label {
        @include toolbarAction();
        margin-right: 0;

        &:hover,
        &:focus {
            color: grayscaleColor(1);
        }
    }
}
