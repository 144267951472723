$font-url: '/fonts';
@import 'component-core.scss';

/**
 * @license
 * MyFonts Webfont Build ID 3355553, 2017-03-07T14:07:47-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: AvenirLT-Medium by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/65-medium/
 * 
 * Webfont: AvenirLT-Roman by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/55-roman/
 * 
 * Webfont: AvenirLT-MediumOblique by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/65-mediumoblique/
 * 
 * Webfont: AvenirLT-BookOblique by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/45-book-oblique/
 * 
 * Webfont: AvenirLT-Black by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/95-black/
 * 
 * Webfont: AvenirLT-HeavyOblique by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/85-heavy-oblique/
 * 
 * Webfont: AvenirLT-Book by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/45-book/
 * 
 * Webfont: AvenirLT-Light by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/35-light/
 * 
 * Webfont: AvenirLT-BlackOblique by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/95-black-oblique/
 * 
 * Webfont: AvenirLT-LightOblique by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/35-light-oblique/
 * 
 * Webfont: AvenirLT-Oblique by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/55-oblique/
 * 
 * Webfont: AvenirLT-Heavy by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/85-heavy/
 * 
 * 
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3355553
 * Licensed pageviews: 250,000
 * Webfonts copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 1981 - 2007 Linotype GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype GmbH, and may not be repro
 * 
 * © 2017 MyFonts Inc
*/

/* @imports must be at top of file, otherwise CSS will not work */

$font-url: '/fonts' !default;

/* We used to count our use of myfonts here because that's where they had it in their
 * sample code, but it makes more sense to move this for two reasons: it's page blocking,
 * (which in China is causing LONG load times) and it's more accurate to count the use
 * on each route initialization since that would count the use on each page "transition".
 * See countMyFontsUsage() for the new way we do this.
*/

@font-face {
    font-family: 'AvenirLT-Medium';
    src: url('#{$font-url}/webfonts/3333A1_0_0.eot');
    src: url('#{$font-url}/webfonts/3333A1_0_0.eot?#iefix')
            format('embedded-opentype'),
        url('#{$font-url}/webfonts/3333A1_0_0.woff2') format('woff2'),
        url('#{$font-url}/webfonts/3333A1_0_0.woff') format('woff'),
        url('#{$font-url}/webfonts/3333A1_0_0.ttf') format('truetype');
}

@font-face {
    font-family: 'AvenirLT-Roman';
    src: url('#{$font-url}/webfonts/3333A1_1_0.eot');
    src: url('#{$font-url}/webfonts/3333A1_1_0.eot?#iefix')
            format('embedded-opentype'),
        url('#{$font-url}/webfonts/3333A1_1_0.woff2') format('woff2'),
        url('#{$font-url}/webfonts/3333A1_1_0.woff') format('woff'),
        url('#{$font-url}/webfonts/3333A1_1_0.ttf') format('truetype');
}

@font-face {
    font-family: 'AvenirLT-MediumOblique';
    src: url('#{$font-url}/webfonts/3333A1_2_0.eot');
    src: url('#{$font-url}/webfonts/3333A1_2_0.eot?#iefix')
            format('embedded-opentype'),
        url('#{$font-url}/webfonts/3333A1_2_0.woff2') format('woff2'),
        url('#{$font-url}/webfonts/3333A1_2_0.woff') format('woff'),
        url('#{$font-url}/webfonts/3333A1_2_0.ttf') format('truetype');
}

@font-face {
    font-family: 'AvenirLT-BookOblique';
    src: url('#{$font-url}/webfonts/3333A1_3_0.eot');
    src: url('#{$font-url}/webfonts/3333A1_3_0.eot?#iefix')
            format('embedded-opentype'),
        url('#{$font-url}/webfonts/3333A1_3_0.woff2') format('woff2'),
        url('#{$font-url}/webfonts/3333A1_3_0.woff') format('woff'),
        url('#{$font-url}/webfonts/3333A1_3_0.ttf') format('truetype');
}

@font-face {
    font-family: 'AvenirLT-Black';
    src: url('#{$font-url}/webfonts/3333A1_4_0.eot');
    src: url('#{$font-url}/webfonts/3333A1_4_0.eot?#iefix')
            format('embedded-opentype'),
        url('#{$font-url}/webfonts/3333A1_4_0.woff2') format('woff2'),
        url('#{$font-url}/webfonts/3333A1_4_0.woff') format('woff'),
        url('#{$font-url}/webfonts/3333A1_4_0.ttf') format('truetype');
}

@font-face {
    font-family: 'AvenirLT-HeavyOblique';
    src: url('#{$font-url}/webfonts/3333A1_5_0.eot');
    src: url('#{$font-url}/webfonts/3333A1_5_0.eot?#iefix')
            format('embedded-opentype'),
        url('#{$font-url}/webfonts/3333A1_5_0.woff2') format('woff2'),
        url('#{$font-url}/webfonts/3333A1_5_0.woff') format('woff'),
        url('#{$font-url}/webfonts/3333A1_5_0.ttf') format('truetype');
}

@font-face {
    font-family: 'AvenirLT-Book';
    src: url('#{$font-url}/webfonts/3333A1_6_0.eot');
    src: url('#{$font-url}/webfonts/3333A1_6_0.eot?#iefix')
            format('embedded-opentype'),
        url('#{$font-url}/webfonts/3333A1_6_0.woff2') format('woff2'),
        url('#{$font-url}/webfonts/3333A1_6_0.woff') format('woff'),
        url('#{$font-url}/webfonts/3333A1_6_0.ttf') format('truetype');
}

@font-face {
    font-family: 'AvenirLT-Light';
    src: url('#{$font-url}/webfonts/3333A1_7_0.eot');
    src: url('#{$font-url}/webfonts/3333A1_7_0.eot?#iefix')
            format('embedded-opentype'),
        url('#{$font-url}/webfonts/3333A1_7_0.woff2') format('woff2'),
        url('#{$font-url}/webfonts/3333A1_7_0.woff') format('woff'),
        url('#{$font-url}/webfonts/3333A1_7_0.ttf') format('truetype');
}

@font-face {
    font-family: 'AvenirLT-BlackOblique';
    src: url('#{$font-url}/webfonts/3333A1_8_0.eot');
    src: url('#{$font-url}/webfonts/3333A1_8_0.eot?#iefix')
            format('embedded-opentype'),
        url('#{$font-url}/webfonts/3333A1_8_0.woff2') format('woff2'),
        url('#{$font-url}/webfonts/3333A1_8_0.woff') format('woff'),
        url('#{$font-url}/webfonts/3333A1_8_0.ttf') format('truetype');
}

@font-face {
    font-family: 'AvenirLT-LightOblique';
    src: url('#{$font-url}/webfonts/3333A1_9_0.eot');
    src: url('#{$font-url}/webfonts/3333A1_9_0.eot?#iefix')
            format('embedded-opentype'),
        url('#{$font-url}/webfonts/3333A1_9_0.woff2') format('woff2'),
        url('#{$font-url}/webfonts/3333A1_9_0.woff') format('woff'),
        url('#{$font-url}/webfonts/3333A1_9_0.ttf') format('truetype');
}

@font-face {
    font-family: 'AvenirLT-Oblique';
    src: url('#{$font-url}/webfonts/3333A1_A_0.eot');
    src: url('#{$font-url}/webfonts/3333A1_A_0.eot?#iefix')
            format('embedded-opentype'),
        url('#{$font-url}/webfonts/3333A1_A_0.woff2') format('woff2'),
        url('#{$font-url}/webfonts/3333A1_A_0.woff') format('woff'),
        url('#{$font-url}/webfonts/3333A1_A_0.ttf') format('truetype');
}

@font-face {
    font-family: 'AvenirLT-Heavy';
    src: url('#{$font-url}/webfonts/3333A1_B_0.eot');
    src: url('#{$font-url}/webfonts/3333A1_B_0.eot?#iefix')
            format('embedded-opentype'),
        url('#{$font-url}/webfonts/3333A1_B_0.woff2') format('woff2'),
        url('#{$font-url}/webfonts/3333A1_B_0.woff') format('woff'),
        url('#{$font-url}/webfonts/3333A1_B_0.ttf') format('truetype');
}
