$font-url: '/fonts';
@import 'component-core.scss';

$color-select-list-inline-open: $color-input-inline-border-focus !default;

.select-list__auto-measure-div {
    @include textFont();
}

.select-list {
    height: $select-list-item-height - 2px; // Adjust size because of border.
    width: 100%;

    &--long .drop-down-menu__list {
        @include dropdownMenuScrollbar();

        // Per style guide, menu should accomodate 10 menu items before scrolling.
        max-height: 10 * $select-list-item-height;
    }

    &.drop-down-menu__main.select-list--inline {
        &:hover .drop-down-menu__label {
            outline: none;
        }

        .drop-down-menu__menu {
            max-width: fit-content; // for inline, we want the menu to be as fitting as possible
        }

        .drop-down-menu__label {
            padding-left: 0;
            border: none;
            background-color: transparent;

            &:hover,
            &:focus {
                color: $color-select-list-inline-open;
                border: none;
                outline: none;
            }

            .drop-down-menu__label-text {
                flex: 0;
                width: 100%;
            }
        }

        /* stylelint-disable-next-line order/order */
        &.select-list.select-list--disabled {
            .drop-down-menu__label {
                // We want to prevent the "regular"-style dropdown's background
                // color from appearing on inline styles.
                background: transparent;
            }
        }
    }

    &.select-list--has-selection .select-list__blank-selection {
        @include textStyle($isOblique: true, $color: grayscaleColor(4));
        display: block;

        &.drop-down-menu-item--active,
        &:hover {
            color: grayscaleColor(5);
        }
    }

    &--always-search.drop-down-menu__main.select-list--with-search {
        outline: none;

        .drop-down-menu__label.drop-down-menu__label--closed {
            height: 32px;
            border: 1px solid $color-input-border;
            padding: 5px 8px;
            outline: none;
        }

        .drop-down-menu__label.drop-down-menu__label--open {
            border: 1px solid $color-input-border;
        }

        .drop-down-menu__menu {
            top: $select-list-item-height;
        }
    }

    &--with-search.drop-down-menu__main {
        outline: 1px solid $color-input-border;

        .glyph {
            display: none;
        }

        .drop-down-menu__label.drop-down-menu__label--open,
        .drop-down-menu__label.drop-down-menu__label--open:hover {
            padding: 5px 8px;

            .drop-down-menu__label-text {
                flex: 1;
            }
        }

        .drop-down-menu__menu {
            top: 32px;
        }

        .drop-down-menu__label-text .select-list__search {
            color: $color-input-text;

            input {
                @include placeholderStyle();
                width: calc(100% - 20px);
                border: none;
                outline: none;
                color: $color-input-text;
                background: transparent;
            }

            /* stylelint-disable-next-line selector-combinator-whitelist */
            input:placeholder-shown + .glyph {
                display: none;
            }

            .glyph {
                display: inline-block;
                color: $color-input-text;
                font-size: 12px;
            }
        }
    }

    &--loading {
        .drop-down-menu__menu {
            pointer-events: none;

            .drop-down-menu-item__label-content {
                opacity: 0.5;
            }
        }
    }

    &--readonly {
        margin: 14px 0 0;
        list-style-type: none;
        padding: 0;

        .select-list--item {
            margin-bottom: 8px;
        }
    }

    &.drop-down-menu__main:not(.drop-down-menu__main--portal) {
        .drop-down-menu__menu {
            &.select-list__menu-top {
                bottom: 32px;
                top: inherit;
            }
        }
    }

    &.drop-down-menu__main {
        &--open a,
        &:hover a,
        a:focus,
        &.select-list--with-search:not(.select-list--always-search) {
            outline: 1px solid $color-input-border-focus;
        }

        &.select-list.select-list--disabled {
            pointer-events: none;
            cursor: default;

            .drop-down-menu__label {
                background: $color-input-disabled-background;
                color: $color-input-disabled-text;
            }
        }

        // Note: This should really be using a theme.
        &--align-right .drop-down-menu__menu {
            right: 1;
            left: auto;
        }

        &--align-left .drop-down-menu__menu {
            left: 1;
            right: auto;
        }

        .drop-down-menu__menu {
            max-width: calc(200% - 2px);
            min-width: calc(100% - 2px);
            top: 33px;

            &.select-list__menu-top {
                @include shadow($shadowDepth: -1);
                transform: translateY(-1px);
                bottom: 0;
            }

            .drop-down-menu__list {
                padding: 0;
                margin-bottom: 0;
                padding-bottom: 12px;
                margin-top: 12px;

                .checkbox-input__checkmark {
                    top: 13px;
                }
            }
        }

        .drop-down-menu__label {
            user-select: none;
            height: 32px;
            border: 1px solid $color-input-border;
            background-color: grayscaleColor(1);
            width: 100%;

            .drop-down-menu__label-text {
                width: calc(100% - 30px);

                .select-list__tooltip-content {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 100%;
                }

                .select-list__tooltip-placeholder {
                    color: grayscaleColor(5);
                }

                .header {
                    display: inline-block;
                }

                .skittle {
                    margin-left: 5px;
                    text-align: center;
                    display: inline-block;
                    width: 17px;
                    height: 17px;
                    -webkit-border-radius: 8.5px;
                    -moz-border-radius: 8.5px;
                    border-radius: 8.5px;
                    font-size: 10px;
                    line-height: 19.5px;
                    color: grayscaleColor(1);
                    background: primaryColor(1); // blue
                }
            }
        }

        .select-list-item--selected {
            @include textFont($weight: 'Black');
        }

        .select-list-item--disabled {
            @include textStyle($color: grayscaleColor(4));
        }

        .select-list-item--initializing {
            @include textStyle($isOblique: true, $color: grayscaleColor(5));
            text-transform: lowercase;

            &:first-letter {
                text-transform: uppercase;
            }

            &:hover {
                background-color: transparent;
                cursor: default;
            }

            // Override &--loading opacity if we're initializing
            .drop-down-menu-item__label-content {
                opacity: 1;
            }
        }

        .select-list-item--header {
            @include textStyle(
                $size: 12px,
                $color: grayscaleColor(5),
                $letterSpacing: 0.025em
            );
            text-transform: uppercase;
            border-bottom: 1px solid grayscaleColor(4);
            padding: 0 24px;
        }
    }

    .select-list__blank-selection {
        display: none;
    }
    
    /* stylelint-disable-next-line order/order */
    @import 'themes/theme_pill.scss';
    @import 'themes/theme__link.scss';
}

.select-list-inline-validation {
    display: flex;
    align-items: center;

    .validationerror--inline {
        // Include margin on both sides to ensure tooltip centers properly.
        margin: 0 $size-xxs;
    }
}
