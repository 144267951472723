$font-url: '/fonts';
@import 'component-core.scss';

.enhanced-dataview-toolbar {
    display: flex;
    align-items: center;
    width: 100%;
    color: textColorForPrimaryColor(1);
    background-color: primaryColor(1);
    position: absolute;
    height: $site-toolbar-height;
    top: -12px;
    left: 0;
    z-index: $zindex-action-bar;

    // for some reason safari and chrome on mac doesn't like the following line, causing layout to be janked
    // so adding this for windows only fixes the issue cross platform
    &.enhanced-dataview-toolbar--windows {
        .enhanced-dataview-toolbar__button-label {
            height: 16px;
        }
    }

    .enhanced-dataview-toolbar__spacer {
        flex-grow: 1;
    }

    .enhanced-dataview-toolbar__counter {
        display: flex;
        align-items: center;

        padding: 0 8px;

        .enhanced-dataview-toolbar__counter-total {
            display: flex;
            text-transform: lowercase;
            margin-right: 16px;
            line-height: 24px;
            height: 24px;
        }

        .enhanced-dataview-toolbar__counter-total-count {
            @include textStyle($color: primaryColor(1), $weight: 'Heavy');
            background: grayscaleColor(1);
            padding: 1px 8px;
            border-radius: 3px;
            margin-right: 8px;
        }

        .enhanced-dataview-toolbar__counter-select-all {
            @include textStyle(
                $color: grayscaleColor(2),
                $isUppercase: true,
                $weight: 'Heavy'
            );
        }
    }

    .enhanced-dataview-toolbar__item {
        @include textStyle(
            $color: grayscaleColor(2),
            $isUppercase: true,
            $weight: 'Medium'
        );
        padding: 0 8px;
        display: flex;
        align-items: center;
    }

    .enhanced-dataview-toolbar__button-label {
        height: 12px;
    }

    .enhanced-dataview-toolbar__item-menu,
    .enhanced-dataview-toolbar__item-button {
        padding: 0 8px;
        margin-right: 32px;
    }

    .enhanced-dataview-toolbar__action-wrapper {
        display: flex;
        align-items: center;
    }

    .enhanced-dataview-toolbar__close {
        $fontSize: 16px;
        $margin: 8px;
        margin-right: $margin;
        font-size: $fontSize;
        width: $fontSize + (2 * $margin);
    }
}
