@include theme('first-run-wizard') {
    .basic-dialog__content {
        padding: 0;
    }

    .stepped-task-dialog__footer {
        background-color: transparent;
        position: absolute;
        bottom: 0;
        left: 50%;
        right: 0;
    }

    .stepped-task-dialog__steps {
        margin: 0 25%;
    }
}
