$font-url: '/fonts';
@import 'component-core.scss';

.loading-indicator {
    @keyframes cssAnimation {
        0% {
            opacity: 0;
        }

        75% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    &--delayed {
        animation: cssAnimation 1s linear forwards;
    }

    &__loader {
        @keyframes rotation {
            0% {
                transform: rotate(0);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid grayscaleColor(3);
        border-top: 2px solid $color-control-basic;
        animation: rotation 1s linear infinite;
    }
}
