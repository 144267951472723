@include theme('brand-lift-campaign-settings') {
  .radio-control {

    .radio-group__glyph {
      height: 44px;
      width: 88px;
      font-size: 44px;
    }

    .radio-control__label {
      width: $size-xxs * 45;
    }

    .glyph-channels-other {
      width: 88px;
    }
  }

  .horizontal-list .horizontal-list__item {
      .radio-control--enabled {
        .radio-control__label--selected,
        .radio-control__label--selected:active {
          background: primaryTint(1, 5);
          border-color: primaryColor(1);
          color: primaryColor(1);
          z-index: 0;
        }
      }
  }
}
