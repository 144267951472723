@include theme('campaign-flights') {
    .date-time-text-input {
        // Ensure input aligns with read-only controls as well.
        margin: 0 $size-xxs;
        // This width is somewhat arbitrary - it's just wide enough to contain
        // the entire date with time.
        min-width: $base-unit * 19; // 152px
    }

    .date-time-text-input--readonly {
        justify-content: center;
    }
}
