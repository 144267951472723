$font-url: '/fonts';
@import 'component-core.scss';


.datatable.datatable--bidlist {
    .datatable__column--delete--header {
        // This is the class assigned to the delete row button in the header
        margin-left: 1px;
    }

    .datatable__row {
        &:hover .datatable__column--delete--content {
            visibility: visible;
        }

        .datatable__column {
            &--delete {
                padding: 0 16px;
            }

            &--delete--header,
            &--delete--content {
                $actionGlyphSize: 10px;
                $actionShadowDepth: 1;
                $actionBadgeSize: 20px;
                @include glyphStyleWithBadge(
                    $glyphSize: $actionGlyphSize,
                    $badgeDiameter: $actionBadgeSize,
                    $glyphColor: textColorForPrimaryColor(1),
                    $badgeColor: primaryColor(1));
                @include shadow($actionShadowDepth);
                cursor: pointer;
                visibility: hidden;
                margin-left: 1px;
            }

            &--delete--header {
                visibility: visible;
            }
        }
    }
}
