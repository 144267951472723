$font-url: '/fonts';
@import 'component-core.scss';

.datatable.datatable--light.datatable--light-no-highlight {
    $hoverColor: grayscaleColor(3);

    .datatable__row {
        .datatable__column {
            &.datatable__column--fixed {
                &:hover {
                    cursor: pointer;
                    background-color: inherit;
                    /* stylelint-disable-next-line function-blacklist */
                    box-shadow: 0 2px 2px 0 rgba($hoverColor, 0.5);
                }
            }
        }
    }

    .datatable__body {
        .datatable__row {
            &:hover {
                cursor: pointer;
                background-color: inherit;
                /* stylelint-disable-next-line function-blacklist */
                box-shadow: 0 3px 3px 0 rgba($hoverColor, 0.8);
            }
        }
    }
}
