$font-url: '/fonts';
@import 'component-core.scss';

.dialog-status-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

    height: 100%;
    overflow: auto;

    &__image {
        margin-bottom: $size-s;
        height: 80px;
        flex: 0 0 auto;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;

        &.dialog-status-page__success {
            margin-top: 0;
            height: 120px;
            width: 120px;
        }
    }

    &__loading {
        margin: $size-xs 0;
        width: 80px;
        height: 80px;
        margin-left: auto;
        margin-right: auto;
    }

    &__title {
        @include pageTitleText();
        margin-bottom: $size-xs;
    }

    &__message {
        @include bodyText();
        min-height: 18px;
    }

    &__timer {
        color: grayscaleColor(5);
        margin-top: $size-xs;
    }

    .percent-bar {
        margin-bottom: $size-xs;
        min-width: 350px;
        max-width: 700px;
        width: 100%;
    }

    .dialog-status-page__stepped-progress-bar {
        min-width: 350px;
        max-width: 100%;
        width: 100%;
    }

    @import 'themes/theme__immediate-action.scss';
}
