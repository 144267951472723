@include theme('action-bar--fullpage') {
    &.button--type-secondary {
        color: grayscaleColor(1);

        &.button--enabled:hover,
        &.button--enabled:focus,
        &.button--enabled:active {
            color: grayscaleColor(3);
        }
    }
}
