@include theme('page-quality') {
    .radio-control {
        width: 180px;
        height: 100px;

        .glyph__inner {
            font-size: 20px;
            height: 22px;
        }

        .radio-group__glyph-label {
            white-space: normal;
            line-height: $size-xs;
            height: 38px;
            margin-top: $size-xxs;
        }
    }

    .radio-control--readonly {
        border: 1px solid grayscaleColor(3);
        padding: $size-xs;
        text-align: center;
    }
}
