$font-url: '/fonts';
@import 'component-core.scss';


.text-control {
    @include standardInput();

    .text-control--readonly {
        @include readOnlyInput($color: $color-input-text);
        line-height: $single-line-input-height;
        text-align: justify;
    }

    .text-control__textarea {
        @include inputStyle();

        height: 100%;
        min-height: 64px;
        padding: 8px;
        resize: none;
        overflow-x: auto;

        &.text-control__textarea--auto-resize {
            overflow: hidden;
        }
    }

    .input--readonly {
        line-height: $single-line-input-height;
    }
    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__readonly-overflow-ellipsis.scss';
    @import 'themes/theme__data-group-card.scss';
    @import 'themes/theme__input-text-center.scss';
    @import 'themes/theme__campaign-ad-group-flights-input.scss';
}
