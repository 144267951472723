$font-url: '/fonts';
@import 'component-core.scss';

.toggle-control-text {
    color: grayscaleColor(6);
}

.toggle-control {
    $height: 16px;
    $width: 32px;
    $toggle-diameter: 20px;

    // Applied when the control is hovered or has focus.
    @mixin activeEffect() {
        box-shadow: 0 1px 6px 0 grayscaleColor(6);
    }

    .toggle-control__input {
        position: absolute;
        margin-left: -9999px;

        // Visually highlight the toggle when the underlying input gets focus.
        /* stylelint-disable-next-line selector-combinator-whitelist */
        &:focus + .toggle-control__toggle:before {
            @include activeEffect();
        }
    }

    .toggle-control__toggle {
        display: block;
        position: relative;
        cursor: pointer;
        outline: none;
        user-select: none;
        padding: 2px;
        width: $width;
        height: $height;
        border-radius: $height;
        background-color: grayscaleColor(3);
        transition: background-color 0.1s 0.35s linear, box-shadow 0.1s 0.4s;

        &:before {
            display: block;
            position: absolute;
            top: -2px;
            left: -2px;
            content: '';
            width: $toggle-diameter;
            height: $toggle-diameter;
            border-radius: 100%;
            box-shadow: 0 1px 4px 0 grayscaleColor(5);
            transition: transform 0.4s, box-shadow 0.1s;
            background-color: grayscaleColor(1);
        }

        &:not(.toggle-control__toggle--disabled):hover:before {
            @include activeEffect();
        }
    }

    .toggle-control__toggle--checked {
        background-color: primaryTint(1, 3);

        &:before {
            transform: translateX($width - $toggle-diameter + 4px);
        }
    }

    .toggle-control__toggle--disabled {
        border: 1px solid grayscaleColor(4);
        background-color: grayscaleColor(2);
        box-shadow: none;
        cursor: default;

        &:before {
            border: 1px solid grayscaleColor(4);
            box-shadow: none;
            background-color: grayscaleColor(2);
            top: -3px;
        }

        &:after {
            // Note: we're intentionally NOT localizing this string, as the control
            // can't accomodate string swell, and the words "on" and "off" are
            // sufficiently universal.
            content: 'off';
            color: grayscaleColor(4);
            position: absolute;
            top: 2px;
            left: 2px;
            font-size: 9px;
        }

        &.toggle-control__toggle--checked {
            &:after {
                content: 'on';
                left: 19px;
                top: 1px;
            }
        }
    }

    .toggle-control__toggle--loading {
        opacity: 0.8;
        pointer-events: none;

        &:before {
            background-color: grayscaleColor(2);
        }
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__stoplight.scss';
}
