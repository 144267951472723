@include theme('split-button-primary') {
    height: $size-m;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .button__background {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.button--type-primary,
    &.button--type-action {
        border-right: 1px var(--txt-color) solid;
    }
}

@include theme('split-button-dropdown') {
    padding: 0;
    height: $size-m;
    width: $size-m;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .button__background {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &.button--type-secondary {
        border-left: 0;
    }
}
