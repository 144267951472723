@include theme('multi-select-filters') {
    // This needs to go here because SelectList overwrites these styles
    &.select-list--multiple {
        &.color-8 {
            .drop-down-menu__label {
                border-left: 4px solid primaryTint(8, 1);
            }
        }
        
        &.color-2 {
            .drop-down-menu__label {
                border-left: 4px solid primaryTint(2, 1);
            }
        }
    }
}
