@include theme('cn-adv-ent') {
    .stepped-progress-bar-step {
        .stepped-progress-bar-step__summary__details {
            width: 350px;
        }
    }
    
    .stepped-progress-bar-step__index {
        font-size: 12px;
    }

    .stepped-progress-bar__step-wrapper {
        &.stepped-progress-bar__step-wrapper--vertical {
            min-height: unset;
        }
    }
}
