@include theme('top-seller-media-type') {
    // We want this dropdown to always appear white. Unfortunately, we're in a terrible
    // selector battle against `SelectListControl.scss` - so we need to add these `select-list`
    // classes to ensure this style is applied.
    &.select-list.select-list--inline {
        .drop-down-menu__label {
            color: grayscaleColor(1);

            &:hover,
            &:focus,
            .drop-down-menu-arrow {
                color: grayscaleColor(1);
                opacity: 0.7;
            }
        }
    }
}
