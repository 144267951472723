$font-url: '/fonts';
@import 'component-core.scss';

.labelled-input {
    display: inline-block;
    margin-right: $size-xs;
    vertical-align: top;

    &--xs {
        width: $base-unit * 9; // 72px
    }

    &--sm {
        width: $base-unit * 20; // 160px;
    }

    &--md {
        width: $base-unit * 31; // 248px;
    }

    &--lg {
        width: $base-unit * 42; // 336px;
    }

    &--xl {
        width: $base-unit * 52; // 424px;
    }

    &--xxl {
        width: $base-unit * 64; // 512px;
    }

    &--auto {
        width: auto;
    }

    &--readonly {
        padding-bottom: $single-line-validation-message-height;
    }

    &--inline {
        display: flex;
        align-items: baseline;

        .labelled-input__label {
            margin-right: 8px;
        }
    }

    &__label {
        @include labelledInputText();
        margin-top: 4px;
        margin-bottom: 5px;

        &__is-required {
            color: primaryColor(7);
        }

        // This is an example of everything wrong with CSS selectors. Figuring out what
        // this applies to is nigh-impossible, so just leaving it for now. Please don't
        // do this.
        /* stylelint-disable-next-line selector-max-type, selector-combinator-whitelist */
        > label > div {
            display: inline-block;
        }
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__bulk-edit-names.scss';
    @import 'themes/theme__contains-table.scss';
    @import 'themes/theme__no-right-margin.scss';
    @import 'themes/theme__toggle.scss';
    @import 'themes/theme__filter.scss';
    @import 'themes/theme__metric-label-text.scss';
    @import 'themes/theme__emphasize-label.scss';
}
