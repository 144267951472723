@import '_flat-card';

@include theme('flat-insights__no-content-padding') {
    @include flatCard();

    .card__inner {
        padding: 0;
    }

    .card__title {
        padding-top: $card-padding;
        padding-right: $card-padding;
        padding-left: $card-padding;
    }
}
