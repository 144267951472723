@include theme('action-navigation-sub-menu') {
    @include resetButtonStyles();

    &.button--standard,
    &.button--flat {
        @include textStyle($size: 14px, $weight: 'Normal', $isUppercase: false);
        height: unset;
        border: unset;
        display: block;
        padding: 8px;
        white-space: nowrap;

        --disabled-bg: transparent;
        --box-shadow: 0;
        --box-shadow-hover: 0;
        --box-shadow-focus: 0;

        .button__background {
            background-color: transparent;
        }
    }
}
