// A mixin to create primary color variables, where tints and shades are generated
// using the `mix` function
@mixin generatePrimaryColor($color, $primaryNumber) {
    --primary#{$primaryNumber}: #{$color};
    --primary#{$primaryNumber}-light: #{lighten($color, 20%)};
    @for $tintNumber from 1 through length($primary-tints) {
        $value: mix(white, $color, nth($primary-tints, $tintNumber));
        --primary#{$primaryNumber}-tint#{$tintNumber}: #{$value};
        --primary#{$primaryNumber}-tint#{$tintNumber}-light: #{lighten($value, 20%)};
    }
    @for $shadeNumber from 1 through length($primary-shades) {
        $value: mix(black, $color, nth($primary-shades, $shadeNumber));
        --primary#{$primaryNumber}-shade#{$shadeNumber}: #{$value};
        --primary#{$primaryNumber}-shade#{$shadeNumber}-light: #{lighten($value, 20%)};
    }
}

// A mixin to create primary color variables, where tints and shades are predefined.
//
// $tints and $shades are maps of shade/tint number to their hex value.
// E.g. $shades: (1: #007CD5, 2: #0060AA, 3: #004580)
@mixin generatePrimaryColorFromHexValues($color, $primaryNumber, $tints, $shades) {
    --primary#{$primaryNumber}: #{$color};
    --primary#{$primaryNumber}-light: #{lighten($color, 20%)};
    @each $tintNumber, $tintColor in $tints {
        --primary#{$primaryNumber}-tint#{$tintNumber}: #{$tintColor};
        --primary#{$primaryNumber}-tint#{$tintNumber}-light: #{lighten($tintColor, 20%)};
    }
    @each $shadeNumber, $shadeColor in $shades {
        --primary#{$primaryNumber}-shade#{$shadeNumber}: #{$shadeColor};
        --primary#{$primaryNumber}-shade#{$shadeNumber}-light: #{lighten($shadeColor, 20%)};
    }
}
