$font-url: '/fonts' !default;
$glyphDir: '#{$font-url}/glyphs';
@font-face {
	font-family: "glyph-font-topsellers";
    	src: url("#{$glyphDir}glyph-font-topsellers.eot?f60cb136-72fc-4da6-bb48-ba76a6382bda?#iefix") format("embedded-opentype"),
    		 url("#{$glyphDir}/glyph-font-topsellers.woff?f60cb136-72fc-4da6-bb48-ba76a6382bda") format("woff"),
    		 url("#{$glyphDir}/glyph-font-topsellers.ttf?f60cb136-72fc-4da6-bb48-ba76a6382bda") format("truetype");
}

.glyph-family-topsellers {
	line-height: inherit;
}

.glyph-family-topsellers:before {
	font-family: glyph-font-topsellers !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}
.glyph-family-topsellers:after {
	font-family: glyph-font-topsellers !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}


.glyph-topsellers-report-avgcpm:before {
    content: "\ea01";
}
.glyph-topsellers-report-avgcpm-after:after {
    content: "\ea01";
}

.glyph-topsellers-report-spend:before {
    content: "\ea02";
}
.glyph-topsellers-report-spend-after:after {
    content: "\ea02";
}

.glyph-topsellers-report-viewability:before {
    content: "\ea03";
}
.glyph-topsellers-report-viewability-after:after {
    content: "\ea03";
}

