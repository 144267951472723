$font-url: '/fonts';
@import 'component-core.scss';

.button {
    font: 400 13.3333px Arial;
    @include textStyle(
        $size: 12px,
        $weight: 'Black',
        $letterSpacing: 0.1em,
        $isUppercase: true
    );
    display: inline-block;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    position: relative;
    background-color: transparent;
    // default button styles we add so links look identical
    appearance: button;

    // links don't obey the same text align rules as buttons do
    &.button--link-adapter {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    // ripple adds some hover style which we need to ignore since we define our own colors
    &.mdc-ripple-surface--primary:before {
        --mdc-theme-primary: transparent;
    }

    &.button--flat {
        height: $size-m;
        padding: 0 $size-xxs;

        --mdc-theme-primary: #{primaryShade(1, 2)};
        --opacity: 0;
        color: var(--txt-color);

        .button__background {
            opacity: var(--opacity);
            background-color: var(--bg-color);
        }

        &.button--enabled:hover,
        &.button--enabled:focus {
            --opacity: 0.05;
        }

        &.button--enabled:focus {
            --opacity: 0.15;
        }

        &.button--enabled:active {
            --opacity: 0.02;
        }

        &.button--type-primary {
            --mdc-theme-primary: #{grayscaleColor(5)};
            --txt-color: #{primaryColor($color-number-control-button-primary)};
            --bg-color: #{primaryColor($color-number-control-button-primary)};
        }

        &.button--type-action {
            --mdc-theme-primary: #{grayscaleColor(5)};
            --txt-color: #{stoplightColor('green')};
            --bg-color: #{stoplightColor('green')};
        }

        &.button--type-secondary {
            --mdc-theme-primary: #{grayscaleColor(7)};
            --txt-color: #{grayscaleColor(7)};
            --bg-color: #{grayscaleColor(7)};
        }

        &.button--disabled {
            --txt-color: #{grayscaleColor(5)};
            --mdc-theme-primary: transparent;
        }
    }

    &.button--standard {
        height: $size-m;
        padding: 0 $size-xs;
        color: var(--txt-color);
        box-shadow: var(--box-shadow);

        --disabled-bg: #{grayscaleColor(3)};
        --disabled-text: #{grayscaleColor(5)};
        --box-shadow: #{nth($shadowDepths, 1)};
        --box-shadow-hover: #{nth($shadowDepths, 2)};
        --box-shadow-focus: #{nth($shadowDepths, 4)};

        .button__background {
            background-color: var(--primary-bg);
        }

        &.button--enabled:hover {
            box-shadow: var(--box-shadow-hover);
            --primary-bg: var(--primary-bg-hover);
        }

        &.button--enabled:focus {
            box-shadow: var(--box-shadow-focus);
            --primary-bg: var(--primary-bg-focus);
        }

        &:active {
            --box-shadow: none;
        }

        &.button--type-action {
            --mdc-theme-primary: #{grayscaleColor(7)};
            --primary-bg: #{stoplightColor('green')};
            --primary-bg-hover: #{stoplightColor('dark-green')};
            // hardcoded to be one step lighter than stoplightColor('green'). stoplightColor('light-green') is too light
            --primary-bg-focus: #5ab159;
            --txt-color: #{grayscaleColor(1)};
        }

        &.button--type-primary {
            --mdc-theme-primary: #{grayscaleColor(7)};
            --primary-bg: #{primaryColor($color-number-control-button-primary)};
            --primary-bg-hover: #{primaryShade(
                $color-number-control-button-primary,
                1
            )};
            --primary-bg-focus: #{primaryTint(
                $color-number-control-button-primary,
                1
            )};
            --txt-color: #{textColorForPrimaryColor(
                $color-number-control-button-primary
            )};
        }

        &.button--type-secondary {
            --mdc-theme-primary: #{primaryColor(1)};
            --primary-bg: transparent;
            --primary-bg-hover: #{primaryColor(1)};
            --primary-bg-focus: #{primaryColor(1)};
            --disabled-bg: transparent;
            --box-shadow: none;
            --box-shadow-hover: none;
            --box-shadow-focus: none;
            --txt-color: #{primaryColor(1)};
            border: 1px solid primaryColor(1);

            &.button--enabled:focus {
                .button__background {
                    opacity: 0.15;
                }
            }

            &.button--enabled:hover {
                .button__background {
                    opacity: 0.05;
                }
            }

            &.button--disabled {
                border-color: var(--disabled-text);
            }
        }

        &.button--disabled {
            border-color: transparent;
            pointer-events: none;
            --box-shadow: none;
            --txt-color: --disabled-text;

            .button__background {
                background-color: var(--disabled-bg);
            }
        }
    }

    &:disabled,
    &.button--disabled {
        --mdc-theme-primary: transparent;
        opacity: 0.5;
        cursor: default;
    }

    &:focus {
        outline: none;
    }

    .button__content {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: $zindex-promoted;

        &__glyph-before {
            margin-right: $size-xxs;
        }

        &__glyph-after {
            margin-left: $size-xxs;
        }
    }

    .button__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $zindex-default;
        border-radius: 3px;
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__add-targeting-tile.scss';
    @import 'themes/theme__combo-button.scss';
    @import 'themes/theme__flush-flat.scss';
    @import 'themes/theme__toolbar-action.scss';
    @import 'themes/theme__accordion.scss';
    @import 'themes/theme__big-button.scss';
    @import 'themes/theme__action-bar--fullpage.scss';
    @import 'themes/theme__display-as-link.scss';
    @import 'themes/theme__action-navigation-primary.scss';
    @import 'themes/theme__action-navigation-primary-right-toolbar.scss';
    @import 'themes/theme__action-navigation-secondary.scss';
    @import 'themes/theme__action-navigation-sub-menu.scss';
    @import 'themes/theme__split-button.scss';
    @import 'themes/theme__link-with-icon.scss';
    @import 'themes/theme__icon-only.scss';
}
