$font-url: '/fonts';
@import 'component-core.scss';

.ac-filter__editor {
    min-width: 400px;
    padding: 0 20px;
    max-height: inherit;
    display: flex;
    flex-direction: column;

    .ac-filter__editor__buttons {
        display: flex;
        flex-direction: row-reverse;
        padding-top: 8px;
        margin-top: 8px;
        border-top: 1px solid grayscaleColor(4);
    }

    .ac-filter__editor__buttons-spacer {
        flex-grow: 1;
    }

    .ac-filters__input {
        display: flex;
        @include standardScrollbar(inherit);
    }
}
