// $weight is one of: 'Light', 'Book', 'Normal', 'Medium', 'Heavy', 'Black'
@mixin textFont($size: 14px, $weight: 'Normal', $isOblique: false) {
    // The 'Normal' weight translates to 'Roman' for non-oblique and 'Oblique' for oblique.
    @include fontFamily($weight, $isOblique);
    font-weight: normal;
    font-size: $size;
}

@mixin fontFamily($weight: 'Normal', $isOblique: false) {
    font-family: 'AvenirLT-' +
        if(
            $weight == 'Normal',
            if($isOblique, 'Oblique', 'Roman'),
            if($isOblique, $weight + 'Oblique', $weight)
        );
}

@mixin textStyle(
    $size: 14px,
    $weight: 'Normal',
    $isOblique: false,
    $color: grayscaleColor(6),
    $letterSpacing: normal,
    $isUppercase: false
) {
    @include textFont($size, $weight, $isOblique);
    color: $color;
    letter-spacing: $letterSpacing;
    text-transform: if($isUppercase, uppercase, none);
}

@mixin cardTitleText {
    @include textStyle(
        $size: 22px,
        $weight: 'Medium',
        $color: grayscaleColor(7)
    );
}

@mixin pageTitleText($color: grayscaleColor(7)) {
    @include textStyle($size: 24px, $weight: 'Medium', $color: $color);
}

@mixin sectionTitleText($color: grayscaleColor(7)) {
    @include textStyle($size: 20px, $weight: 'Medium', $color: $color);
}

@mixin subSectionTitleText($color: grayscaleColor(7)) {
    @include textStyle($size: 16px, $weight: 'Medium', $color: $color);
}

@mixin bodyText($color: grayscaleColor(6)) {
    @include textStyle($size: 14px, $color: $color);
}

@mixin labelledInputText($isOblique: false) {
    @include textStyle(
        $size: 14px,
        $color: $color-input-label,
        $isOblique: $isOblique,
        $letterSpacing: 0.025em
    );
}

@mixin toolbarItemText($color) {
    @include textStyle($size: 10px, $color: $color, $isUppercase: true);
}

@mixin metricLabelText($color: grayscaleColor(5)) {
    @include textStyle(
        $size: 11px,
        $color: $color,
        $letterSpacing: 0.1em,
        $isUppercase: true
    );
}

@mixin biddingPageInsightsCardTitleText() {
    @include textStyle(
        $size: 16px,
        $weight: 'Heavy',
        $color: grayscaleColor(7),
        $letterSpacing: 0.01em
    );
    margin-bottom: $size-xxs;
}

@mixin forecastPageResultText($color: grayscaleColor(6)) {
    @include textStyle($size: 30px, $color: $color);
}

@mixin chartMetricText($color: grayscaleColor(6)) {
    @include textStyle($size: 13px, $color: $color);
}

@mixin headerMetricText($color: grayscaleColor(7)) {
    @include textStyle($size: 18px, $weight: 'Heavy', $color: $color);
}

@mixin cardMetricText($color: grayscaleColor(6)) {
    @include textStyle($size: 16px, $color: $color);
}

@mixin toolTipText() {
    @include textStyle($size: 11px, $color: grayscaleColor(1));
}

@mixin dropDownMenuHeader() {
    @include textStyle($size: 14px, $color: grayscaleColor(6), $weight: 'Heavy', $isUppercase: true);
}

@mixin dropDownMenuText() {
    @include textStyle($size: 14px, $color: grayscaleColor(6));
}

@mixin siteToolbarText() {
    @include textStyle(
        $size: 12px,
        $color: $color-site-toolbar-text,
        $letterSpacing: 0.1em,
        $isUppercase: true
    );
}

@mixin toolbarAction(
    $color: $color-toolbar-action-text,
    $hover: grayscaleColor(1)
) {
    @include textStyle(
        $color: $color,
        $size: 0.75 * $size-xs,
        $letterSpacing: 0.1em,
        $isUppercase: true
    );
    line-height: $size-xs;
    margin-right: $size-l;

    &:hover:enabled,
    &:focus:enabled {
        color: $hover;
    }

    .glyph {
        font-size: $size-xs;
    }
}

@mixin finalHierarchyCategoryNameText() {
    @include textStyle($color: grayscaleColor(7), $weight: 'Black');
}

@mixin pageNavLink($size: 22px) {
    @include textStyle(
        $color: grayscaleColor(7),
        $size: $size,
        $weight: 'Medium'
    );

    cursor: pointer;
    text-decoration: none;
    margin-bottom: $size-xs;

    &:hover {
        opacity: 0.8;
    }

    .glyph {
        font-size: 0.6em;
        padding-right: $size-xxs;
    }
}

@mixin link(
    $size: 14px,
    $isUppercase: false,
    $weight: 'Normal',
    $letterSpacing: normal
) {
    @include textStyle(
        $color: primaryShade(1, 1),
        $size: $size,
        $isUppercase: $isUppercase,
        $weight: $weight,
        $letterSpacing: $letterSpacing
    );
    text-decoration: none;
    cursor: pointer;

    &:focus,
    &:hover {
        color: primaryShade(1, 2);
        outline: none;
    }
}
