$font-url: '/fonts';
@import 'component-core.scss';

.row-delete-button {
    &:hover,
    &:focus {
        opacity: 0.8;
    }

    &.row-delete-button--readonly {
        display: none;
    }
}
