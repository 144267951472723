@include theme('link') {
    &.drop-down-menu__main {
        &--open a,
        &:hover a,
        a:focus,
        &.select-list--with-search:not(.select-list--always-search) {
            outline: none;
        }
    }

    .drop-down-menu__label {
        @include link();
    }
}
