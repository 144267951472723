@include theme('combo-button__left-node') {
    &.button--flat.button--type-secondary {
        border-radius: 16px 0 0 16px;
        @include link($size: 16px);

        .button__background {
            border-radius: 16px 0 0 16px;
        }
    }
}

@include theme('combo-button__right-node') {
    border-left: 2px solid grayscaleColor(4);
    border-radius: 0 16px 16px 0;

    &.button--flat.button--type-secondary {
        .button__background {
            border-radius: 0 16px 16px 0;
        }
        
        @include textStyle(
            $size: 16px,
            $weight: 'Normal',
            $color: grayscaleColor(5)
        );
    }
}
