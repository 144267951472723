@include theme('miaozhen-settings') {
    .radio-control {
        width: 273px;
        height: 160px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .radio-group__icon{
        > svg {
            height: 74px; // arbitrary, from UX
        }
    }

    .radio-group__glyph-label{
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 1px;
        color: #0099FA;
    }
    .radio-group__glyph-label-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    
    
}