@include theme('drop-down-panel') {
    &:hover .drop-down-menu__label {
        outline: none;
    }

    &.drop-down-menu__main {
        display: block;
        max-height: 0;
    }
   
    &.drop-down-menu__main--align-right {
        .drop-down-menu__menu {
            left: 0;
            top: 0 !important; // needs a better solution, but this is necessary for a quick fix after the changes in CX-4637 overrode the CSS
        }
    }

    .drop-down-menu__list {
        margin-block-start: 0;
        margin-block-end: 0;
    }

    .drop-down-menu__menu.drop-down-menu__menu--long {
        min-width: 500px;
    }

    .drop-down-menu__label {
        border: none;
        background-color: transparent;
        padding: 0;
        visibility: collapse;
        overflow: hidden;

        &:hover,
        &:focus {
            border: none;
            outline: none;
        }

        .drop-down-menu__label-text {
            flex: 0;
            width: 100%;
        }
    }
}
