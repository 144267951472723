$font-url: '/fonts';
@import 'component-core.scss';


.time-control {
    display: flex;
    justify-content: flex-start;
    
    &.time-control--readonly {
        display: inline-block;
        line-height: $size-m;
    }

    &.time-control--disabled {
        .time-control__input-wrapper {

            overflow: hidden;
            background: grayscaleColor(2);
            padding: 1px;
            
            &:hover,
            &:focus-within {
                outline: none;
            }
        }

        .time-control__drop-down,
        .time-control__drop-down {
            margin-top: -2px;
            
            &.time-control__drop-down--empty {
                color: grayscaleColor(2);
            }
        }
    }

    .time-control__input-wrapper {
        @include inputStyle();
        background: grayscaleColor(1);
        display: flex;
        width: $size-xxxl;
        margin-right: $size-xs;
        padding: unset;
    }
    

    .time-control__minute,
    .time-control__hour {
        flex: 0 0 12px;
    }

    .time-control__hour {
        transform: translateX(8px);
    }

    .time-control__colon {
        flex-grow: 1;
        align-self: center;
        text-align: center;
    }

    .time-control__switch {
        width: 60px;
    }
}
