@include theme('pill-close-button') {
    // Fade pills out by default.
    opacity: 0.8;

    // Give button an active state.
    &:hover,
    &:focus {
        opacity: 1;
    }

    // Center the "close" icon within the button.
    .glyph__inner {
        vertical-align: 1px;
    }
}
