$font-url: '/fonts';
@import 'component-core.scss';

.fixed-content-control {
    @include inputStyle();
    position: relative;
    display: flex;
    background: grayscaleColor(1);
    align-items: baseline;

    &.fixed-content-control--disabled,
    &--disabled:hover {
        background-color: grayscaleColor(2);
        opacity: 1;
    }

    &.fixed-content-control--readonly,
    &.fixed-content-control--readonly:hover {
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;

        .fixed-content-control__fixed-content {
            margin-right: 4px;
        }
    }

    &.fixed-content-control--after {
        flex-direction: row-reverse;
    }

    &.fixed-content-control--icon .fixed-content-control__fixed-content {
        margin-right: 4px;
        height: 1em;
        align-self: center;
    }

    &.fixed-content-control--error {
        /* stylelint-disable-next-line selector-max-attribute */
        &:not([disabled]) {
            border-color: $color-validation-error-border;
            outline: none;

            /* stylelint-disable-next-line selector-max-attribute */
            &:hover,
            /* stylelint-disable-next-line selector-max-attribute */
            &:focus-within {
                border-color: $color-validation-error-border;
                box-shadow: 0 0 0 1px
                    rgba($color-validation-error-border-rgb, 0.3);/* stylelint-disable function-blacklist */
                outline: none;
            }
        }

        .text-control.input--error:not(.input--inline) input {
            border: none;

            &:hover,
            &:focus,
            &:focus-within {
                box-shadow: none;
            }
        }
    }

    &.fixed-content-control--inline {
        @include inlineInput();
        margin-bottom: 3px;
        background: transparent;

        &.fixed-content-control--error {
            /* stylelint-disable-next-line selector-max-attribute */
            &:hover,
            /* stylelint-disable-next-line selector-max-attribute */
            &:focus-within {
                box-shadow: none;
            }
        }

        // This block is responsible for overriding the `.fixed-content-control__control`
        // block below. (The block below is for normal controls; this block is for inline.)
        .input--inline,
        .input--inline:hover,
        .input--inline:focus,
        .input--error.input--inline,
        .input--error.input--inline:hover,
        .input--error.input--inline:focus {
            input,
            input:hover,
            input:focus {
                border-bottom: none;
                height: 24px;
                margin-top: initial;
            }
        }
    }

    &:not(.fixed-content-control--inline) {
        .validationerror {
            position: absolute;
            left: 0;
        }
    }

    .fixed-content-control__control {
        flex-grow: 1;

        // Note: For inline controls, these styles are intended to be overriden by those in
        // the `.fixed-content-control--inline` block above. If you add any selectors here,
        // be sure to test inline controls as well, and potentially update the block above.
        /* stylelint-disable-next-line selector-max-attribute */
        input,
        input:focus,
        input:active,
        input:hover:not([disabled]),
        .input--error input,
        .input--error input:hover,
        .input--error input:focus {
            outline-width: 0;
            border-width: 0;
            height: $single-line-input-height - 2px;
        }
    }

    .validationerror {
        margin-bottom: 12px;
    }

    .fixed-content-control__fixed-content {
        flex-shrink: 0;
    }

    /* stylelint-disable-next-line order/order */
    @import 'themes/theme__currency-input.scss';
    @import 'themes/theme__device-id-list.scss';
    @import 'themes/theme_data-group-browse.scss';
}
