@include theme('header') {
    @include dropDownMenuHeader();

    // In the case of a dropdown menu item that represents a header
    // we don't want any styling on mouse hover
    &:hover,
    &:active {
        background: none;
        background-color: transparent;
        border: none;
        cursor: default;
    }
}
