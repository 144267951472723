@include theme('pill') {
    &.drop-down-menu__main {
        &:hover a {
            outline: none;
        }
    }

    .drop-down-menu__label {
        border: 1px solid transparent;
        border-bottom-left-radius: size-from-base(2) 50%;
        border-bottom-right-radius: size-from-base(2) 50%;
        border-top-left-radius: size-from-base(2) 50%;
        border-top-right-radius: size-from-base(2) 50%;
        padding: 0 16px;

        color: primaryShade(1, 1);
        background-color: primaryTint(1, 4);
        height: auto;
        line-height: 2;
        outline: none;

        &:hover,
        &:focus {
            outline: none;
            color: primaryShade(1, 2);
            border-color: primaryShade(1, 2);
        }
    }

    /* The drop down menu width is not related to size of the pill, and thus should not have a max-width */
    .drop-down-menu__menu {
        max-width: initial;
    }
}
