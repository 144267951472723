$font-url: '/fonts';
@import 'component-core.scss';

.immediate-action-dialog {
    display: flex;
    flex-direction: column;

    .immediate-action-dialog__content {
        // This ensures that the content takes up the rest of the
        // room that the header and the footer didn't take up.
        flex-grow: 1;
        padding: 0 $size-m;
    }

    .immediate-action-dialog__footer {
        height: $size-xxl;
        min-height: $size-xxl;
        max-height: $size-xxl;
    }
}
