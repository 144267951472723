@import '_flat-card';

@include theme('insight-card') {
    @include flatCard();

    // Ensure that only themed card inherits padding.
    /* stylelint-disable-next-line selector-combinator-whitelist */
    > .card__inner {
        padding: $size-m;
    }
}
