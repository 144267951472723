@include theme('proposal-activation') {
    $glyphCircleSize: 20px;

    .stepped-progress-bar-step {
        &.stepped-progress-bar-step--vertical {
            .stepped-progress-bar-step__summary-wrapper {
                top: 0;
            }
        }

        .stepped-progress-bar-step__circle {
            height: $glyphCircleSize;
            width: $glyphCircleSize;
            max-width: $glyphCircleSize;
        }

        .stepped-progress-bar-step__glyph-complete {
            font-size: 12px;
            padding-top: 2px;
        }

        .stepped-progress-bar-step--static-loading-glyph {
            font-size: 13px;
            margin-top: 2px;
        }

        .stepped-progress-bar-step__glyph-disabled {
            font-size: 13px;
            margin-top: -4px;
        }
    }
}
