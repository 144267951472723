@include theme('audience-search') {
    height: $size-xl + 2;

    .drop-down-menu-item__label-content {
        line-height: $size-xl;

        /* stylelint-disable-next-line selector-combinator-whitelist */
        > :first-child {
            padding: 0 0 0 24px;
        }
    }
}
