// The semantic palette consists of colors that serve specific purposes in the application, but can be
// overridden in whitelabels (by simply defining different values for the variables below (right hand side is the variable to override).
//
// When writing components, look here to see if you should use one of the existing semantic palette colors
// instead of using a "hardcoded" palette color in your stylesheet.
//

// Site Toolbar Colors

$color-site-toolbar-background: var(--color-site-toolbar-background);
$color-site-toolbar-text: var(--color-site-toolbar-text);

// Breadcrumbs Colors

$color-link: var(--color-link);
$color-breadcrumb-item: var(--color-breadcrumb-item);
$color-breadcrumb-item-active: var(--color-breadcrumb-item-active);

// Dialog Colors

$color-dialog-header-background: var(--color-dialog-header-background);
$color-dialog-header-text: var(--color-dialog-header-text);

$color-dialog-content-background: var(--color-dialog-content-background);

// Control Colors

$color-control-basic: var(--color-control-basic);

$color-number-control-button-primary: 1; // NOTE: This is a primary color number

$color-number-control-dialog-step-current: 1; // NOTE: This is a primary color number

// Note that we're defining the _index_ of the color we're using instead of the color itself.
// This allows whitelabelers to quickly & easily change the color without worrying about shades & tints for each of the various states.
$color-control-toggle-enabled-primary-color-index: 6;

// Validation Colors

$color-validation-error-text: var(--color-validation-error-text);
$color-validation-error-border: var(--color-validation-error-border);
$color-validation-error-border-rgb: var(--color-validation-error-border-rgb);

// Help Colors

$color-help-button: var(--color-help-button);
$color-help-button-background: var(--color-help-button-background);
$color-help-button-hover: var(--color-help-button-hover);
$color-help-button-hover-background: var(--color-help-button-hover-background);
$color-help-button-open: var(--color-help-button-open);
$color-help-button-open-background: var(--color-help-button-open-background);
$color-help-border: var(--color-help-border);
$color-help-background: var(--color-help-background);

// Targeting Type Colors

$color-adgroup-targeting-rail: var(--color-adgroup-targeting-rail);
$color-adgroup-targeting-rail-text: var(--color-adgroup-targeting-rail-text);
$color-adgroup-targeting-manual: var(--color-adgroup-targeting-manual);
$color-adgroup-targeting-manual-text: var(
    --color-adgroup-targeting-manual-text
);
$color-adgroup-targeting-auto: var(--color-adgroup-targeting-auto);
$color-adgroup-targeting-auto-text: var(--color-adgroup-targeting-auto-text);

// Card Colors

$color-card-border: var(--color-card-border);
$color-card-background: var(--color-card-background);

// Tool Tips

$color-tooltip-text: var(--color-tooltip-text);
$color-tooltip-background-default: var(--color-tooltip-background-default);
$color-tooltip-background-error: var(--color-tooltip-background-error);

// Callouts

$color-callout-text: grayscaleColor(7) !default;
$color-callout-sub-text: grayscaleColor(5) !default;
$color-callout-background-default: grayscaleColor(2) !default;

// Drop Down Menus

$color-drop-down-menu-background: var(--color-drop-down-menu-background);
$color-drop-down-menu: var(--color-drop-down-menu);

// Activity Log Charts

$color-activity-log-chart-series-one: var(
    --color-activity-log-chart-series-one
);
$color-activity-log-chart-series-two: var(
    --color-activity-log-chart-series-two
);

// Other Colors

$color-horizontal-rule: var(--color-horizontal-rule);

$color-recommendations-indicator: var(--color-recommendations-indicator);
$color-recommendations-indicator-text: var(
    --color-recommendations-indicator-text
);

$color-labelled-value-label: var(--color-labelled-value-label);

$color-lightest-gray-background: var(--color-lightest-gray-background);
$color-lightest-gray-background-border: var(
    --color-lightest-gray-background-border
);

// Input
$color-input-border: var(--color-input-border);
$color-input-text: var(--color-input-text);
$color-input-label: var(--color-input-label);
$color-input-border-focus: var(--color-input-border-focus);
$color-input-inline-border-focus: var(--color-input-inline-border-focus);
$color-input-place-holder: var(--color-input-place-holder);
$color-input-place-holder-focus: var(--color-input-place-holder-focus);
$color-input-disabled-text: var(--color-input-disabled-text);
$color-input-disabled-background: var(--color-input-disabled-background);

// Ad Group Type Color Variations

// Template
$color-adgroup-type-template: var(--color-adgroup-type-template);

// Programmatic Guaranteed
$color-adgroup-type-programmatic-guaranteed: var(
    --color-adgroup-type-programmatic-guaranteed
);

// Toolbar Actions
$color-toolbar-action-text: var(--color-toolbar-action-text);
