$font-url: '/fonts' !default;
$glyphDir: '#{$font-url}/glyphs';
@font-face {
	font-family: "glyph-font-buyhomepage";
    	src: url("#{$glyphDir}glyph-font-buyhomepage.eot?f60cb136-72fc-4da6-bb48-ba76a6382bda?#iefix") format("embedded-opentype"),
    		 url("#{$glyphDir}/glyph-font-buyhomepage.woff?f60cb136-72fc-4da6-bb48-ba76a6382bda") format("woff"),
    		 url("#{$glyphDir}/glyph-font-buyhomepage.ttf?f60cb136-72fc-4da6-bb48-ba76a6382bda") format("truetype");
}

.glyph-family-buyhomepage {
	line-height: inherit;
}

.glyph-family-buyhomepage:before {
	font-family: glyph-font-buyhomepage !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}
.glyph-family-buyhomepage:after {
	font-family: glyph-font-buyhomepage !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}


.glyph-buyhomepage-megaphone:before {
    content: "\ea01";
}
.glyph-buyhomepage-megaphone-after:after {
    content: "\ea01";
}

.glyph-buyhomepage-rocket:before {
    content: "\ea02";
}
.glyph-buyhomepage-rocket-after:after {
    content: "\ea02";
}

.glyph-buyhomepage-staggered-star:before {
    content: "\ea03";
}
.glyph-buyhomepage-staggered-star-after:after {
    content: "\ea03";
}

.glyph-buyhomepage-star:before {
    content: "\ea04";
}
.glyph-buyhomepage-star-after:after {
    content: "\ea04";
}

