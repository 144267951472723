$font-url: '/fonts';
@import 'component-core.scss';

.validationerror {
    &.validationerror--standard {
        margin-top: 6px;
        margin-bottom: 1px;
        color: $color-validation-error-text;
        line-height: $single-line-validation-message-height - 7px;
        font-size: $single-line-validation-message-height - 8px;
    }
}

.validationerror-circle {
    font-size: 75%;
    position: relative;
    top: -2px;
}
